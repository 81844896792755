import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(
  {
    progressBarTitleStyle: (props: any) => ({
      fontSize: "0.8vw",
      fontWeight: 700,
      color: "white",
    }),

    progressBarFooterStyle: (props: any) => ({
      fontSize: "0.8vw",
      color: "white",
    }),

    progressBarContainerStyle: (props: any) => ({
      position: "relative",
      padding: "3% 10% 3% 4%",
      background: "#213735",
      border: "1px solid #4E6361",
      borderRadius: "5px",
      marginBottom: "10px",
    }),

    progressBarContainer: (props: any) => ({
      padding: "3% 0%",
    }),

    progressBarTrackerStyle: (props: any) => ({
      width: "100%",
      height: "0.8vh",
      backgroundColor: "#122929",
      borderRadius: "0.5vh",
      // overflow: "hidden",
      position: "relative",
    }),

    progressBarValueStyle: (props: any) => ({
      position: "absolute",
      left: 0,
      top: "-50%",
      bottom: "-50%",
      // height: "100%",
      background: props?.progressBarValueColor,
      borderRadius: "1vh",
      width: props?.progressBarValue
        ? `${props?.progressBarValue}%`
        : "0%", /* Adjust this value to set the initial progress */
      // transition: `width 0.3s ease-in-out`,
      // transition: `width 10s linear`, 
      transition: `all 500ms ease-in-out`,
      overflow: "hidden",
    }),

    progressBarTrackBlocks: (props: any) => ({
      display: "inline-block",
      width: "0.2vw",
      height: "3vh",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      marginLeft: "0.5vw",
      transform: "rotateZ(20deg) translateY(-5px)",
      zIndex: 10000,
    }),
  },
  { index: 1 }
);
export default useStyles;
