//@ts-nocheck
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { subDays } from "date-fns";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import useStyles from "./styles";


const Calendar = (props:any) => {
  const {startDate, setStartDate} = props;
  const { calendarMainLayout, calendarInnerLayout } = useStyles();
  const [isFocused, setIsFocused] = useState(false);

  const handleDateChange = (date) => {
    setStartDate(date);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const minSelectableDate = subDays(new Date(), 2);

  return (
    <div className={calendarMainLayout}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <FormControl fullWidth>
          <DatePicker
            // label="Select Date"
            views={["year", "month", "day"]}
            value={startDate}
            onChange={handleDateChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disableFuture
            minDate={minSelectableDate}
            format="MM-dd-yyyy"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Date"
                // className={calendarTextField}
                // InputLabelProps={{
                //   shrink: true,
                // }}
                InputProps={{
                  classes: {
                    input: calendarInnerLayout,
                  },  }}
              />
            )}
          />
        </FormControl>
      </LocalizationProvider>
    </div>
  );
};

export default Calendar;
