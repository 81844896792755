import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(
  {
    customSelect: () => ({
      minWidth: "100px !important",
      minheight: "5px !important",
      "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
        {
          paddingRight: "150px !important",
        },
      "& .MuiPaper-root": {
        border: "1px solid #fff !important",
      },
      "& .MuiSelect-select": {
        padding: "10px 11px",
      },
      "& .MuiInputBase-root": {
        borderRadius: "5px",
        fontSize: 14,
        // lineHeight: 21,
        textAlign: "left",
        fontWeight: "bold",
        position: "relative",
        fontFamily: "Nunito Sans",
        color: "#fff",
        "& .MuiSvgIcon-root": {
          position: "absolute",
          right: "10px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          color: "#fff",
          border: `1px solid #fff !important`,
        },
      },

      "& .MuiSelect-iconOutlined": {
        color: `#fff !important`,
      },
    }),

    tableSelect: () => ({
      "& .MuiInputBase-root": {
        marginTop: 5,
      },
      "& .MuiSelect-select": {
        padding: "0 30px 0 0 !important",
        color: `#ffffff !important`,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        display: "none !important",
      },
      "& .MuiFormLabel-root": {
        color: `#ffffff !important`,
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: `#ffffff !important`,
      },
      "& .MuiSvgIcon-root": {
        color: `#ffffff !important`,
      },
      "& .MuiInput-root": {
        color: `#ffffff !important`,
      },
      "& .MuiInput-root:before": {
        display: "none",
      },
      "& .MuiInput-root:after": {
        display: "none",
      },
    }),

    customSvgClass: () => ({
      position: "absolute",
      right: 4,
      top: 15,
      "& .MuiSelect-iconOpen": {
        fill: "#FF5910 !important",
      },
    }),

  },
  { index: 1 }
);
export default useStyles;
