import { useState, useEffect, useRef, useMemo } from "react";
import ArrowRightIcon from "../../assets/profile-dropdown-arrow.svg";
import ArrowDownIcon from "../../assets/profile-dropdown-arrow.svg";
import SearchIcon from "../../assets/Union.svg";
import ProgressBarAccordianListItem from "components/ProgressBarAccordianListItem";
import SsOptLoader from "../../assets/SS-Opt-loader.gif";
import { List } from "react-virtualized";
import {
  ContentContainer,
  RootContainer,
  TitleContainer,
  TabContainer,
  TabItem,
  ContentItemContainer,
  ContentItemTitle,
  ArrowContainer,
  RootListContainer,
  TabListContainer,
  SearchButton,
  SearchInput,
  SearchContainer,
  SearchCloseButton,
  NoResultText,
  ResetButton,
} from "./styles";

interface Elements {
  title: string;
  min: number;
  max: number;
  step: number;
  value: number;
}

// interface TabList {
//   name: string;
//   items: Elements[];
// }

// interface MainProps {
//   id: string;
//   title: string;
//   tabList: TabList[];
//   expandedTab: string | any;
// }

const ListItem = (props: any) => {
  const { data, selectedRightObject } = props;

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [showSearch, setShowSearch] = useState<boolean>(false);

  const [currentIndex, setCurrentIndex] = useState<any>();
  const [searchString, setSearchString] = useState<string | undefined>("");
  // const [searchResults, setSearchResults] = useState<any[]>(
  //   data &&
  //     currentIndex >= 0 &&
  //     currentIndex !== null &&
  //     data[currentIndex][
  //       tabIndex === 0
  //         ? "category1"
  //         : tabIndex === 1
  //         ? "category2"
  //         : "category3"
  //     ].listData
  // );

  const listData = useMemo(() => {
    if (
      data &&
      currentIndex !== null &&
      currentIndex >= 0 &&
      data[currentIndex]
    ) {
      return data[currentIndex][
        tabIndex === 0
          ? "category1"
          : tabIndex === 1
          ? "category2"
          : "category3"
      ].listData;
    }
    return [];
  }, [data, currentIndex, tabIndex]);

  const searchResults = useMemo(() => {
    if (searchString) {
      return listData.filter((item: any) =>
        item.title.toLowerCase().includes(searchString.toLowerCase())
      );
    }
    return listData;
  }, [listData, searchString]);

  const searchTextRef = useRef("");

  useEffect(() => {
    // data &&
    //   currentIndex >= 0 &&
    //   currentIndex !== null &&
    //   setSearchResults(
    //     data[currentIndex][
    //       tabIndex === 0
    //         ? "category1"
    //         : tabIndex === 1
    //         ? "category2"
    //         : "category3"
    //     ].listData
    //   );
    data &&
      currentIndex >= 0 &&
      currentIndex !== null &&
      onSearch(
        data[currentIndex][
          tabIndex === 0
            ? "category1"
            : tabIndex === 1
            ? "category2"
            : "category3"
        ].listData,
        searchTextRef.current
      );
  }, [currentIndex, data]);

  // let tabsList: any = [];
  // if (currentIndex === 5) {
  //   tabsList = [{ label: "RESTROOMS" }];
  // } else {
  //   tabsList = [
  //     { label: "BEVERAGES" },
  //     { label: "CONCESSIONS" },
  //     { label: "RESTROOMS" },
  //   ];
  // }

  const tabsList = useMemo(() => {
    if (currentIndex === 5) {
      return [{ label: "RESTROOMS" }];
    } else {
      return [
        { label: "BEVERAGES" },
        { label: "CONCESSIONS" },
        { label: "RESTROOMS" },
      ];
    }
  }, [currentIndex]);

  const handleAccordionClick = (event: any, index: number) => {
    searchTextRef.current = "";
    setTabIndex(0);
    if (currentIndex === index) {
      setCurrentIndex(null);
    } else {
      setCurrentIndex(index);
    }
    setSearchString("");
    setShowSearch(false);
    event.stopPropagation();
  };

  const onSearch = (list: any, value: any) => {
    searchTextRef.current = value;
    setSearchString(value);
    // const search = list?.filter((item: any) =>
    //   item.title.toLowerCase().includes(searchString?.toLowerCase())
    // );
    // setSearchResults(search);
  };

  const handleTabChange = (e: any, index: number) => {
    setTabIndex(index);
    e.stopPropagation();
  };

  const isInitialRender = useRef(1);

  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(
    isInitialRender.current === 1 ? true : false
  );

  useEffect(() => {
    setSearchString("");
    setShowSearch(false);
    setCurrentIndex(null);
    if (isInitialRender.current <= 2) {
      // Skip the logic for the initial render
      isInitialRender.current += 1;
    } else {
      setIsDataLoaded(false);
      searchTextRef.current = "";
      setTimeout(() => {
        setIsDataLoaded(true);
      }, 500);
    }
  }, [selectedRightObject]);

  

  const progressBarAccordianListItemUseMemo = useMemo(
    () =>
      searchResults?.length > 0 ? (
        searchResults?.every((subList: any) => subList?.count) ? (
          searchResults?.map((subList: any) => {
            return (
            <ProgressBarAccordianListItem
            progressBarTitle={subList?.title}
            progressBarValue={subList?.count}
          />
            );
          })
        ) : (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={SsOptLoader} width={"10%"} />
            </div>
          </>
        )
      ) : (
        <NoResultText>No Result Available</NoResultText>
      ),
    [searchResults]
  );

  return (
    <>
      {useMemo(() => {
        if (isDataLoaded) {
          return data?.map((item: any, index: number) => (
            <RootContainer
              key={index}
              style={{
                background: index === currentIndex ? "#092E2B" : "#031312",
              }}
            >
              <RootListContainer
                onClick={(e: any) => handleAccordionClick(e, index)}
              >
                <TitleContainer>{item?.level}</TitleContainer>
                <ArrowContainer>
                  <img
                    style={{
                      width: "1.2vw",
                      transform:
                        index === currentIndex
                          ? "rotate(0deg)"
                          : "rotate(270deg)",
                    }}
                    src={
                      index === currentIndex ? ArrowDownIcon : ArrowRightIcon
                    }
                  />
                </ArrowContainer>
              </RootListContainer>
              {currentIndex === index && (
                <ContentContainer>
                  <TabListContainer>
                    {showSearch ? (
                      <SearchContainer>
                        <SearchInput
                          type="text"
                          placeholder="Search"
                          value={searchString}
                          onChange={(evt: any) =>
                            onSearch(
                              item?.[
                                tabIndex === 0
                                  ? "category1"
                                  : tabIndex === 1
                                  ? "category2"
                                  : "category3"
                              ].listData,
                              evt?.target?.value
                            )
                          }
                        />
                        <ResetButton
                          onClick={() => {
                            setSearchString("");
                            searchTextRef.current = "";
                          }}
                        >
                          X
                        </ResetButton>
                      </SearchContainer>
                    ) : (
                      <TabContainer>
                        {tabsList?.map(
                          (listItem: any, tabIndexItem: number) => {
                            return (
                              <TabItem
                                key={tabIndexItem}
                                selected={tabIndexItem === tabIndex}
                                onClick={(e: any) =>
                                  handleTabChange(e, tabIndexItem)
                                }
                              >
                                {listItem.label}
                              </TabItem>
                            );
                          }
                        )}
                      </TabContainer>
                    )}
                    {showSearch ? (
                      <SearchCloseButton
                        onClick={() => {
                          setSearchString("");
                          setShowSearch(false);
                          searchTextRef.current = "";
                        }}
                      >
                        X
                      </SearchCloseButton>
                    ) : (
                      <SearchButton
                        src={SearchIcon}
                        onClick={() => setShowSearch(true)}
                      />
                    )}
                  </TabListContainer>
                  {/* {searchString ? (
                        searchResults?.length ? (
                          searchResults?.map((subList: any) => {
                            return (
                              <ProgressBarAccordianListItem
                                progressBarTitle={subList?.title}
                                progressBarValue={subList?.count}
                              />
                            );
                          })
                        ) : (
                          <NoResultText>No Result Available</NoResultText>
                        )
                      ) 
                      : (
                        item?.[
                          tabIndex === 0
                            ? "category1"
                            : tabIndex === 1
                            ? "category2"
                            : "category3"
                        ].listData?.map((subList: any) => {
                          return (
                            <ProgressBarAccordianListItem
                              progressBarTitle={subList?.title}
                              progressBarValue={subList?.count}
                            />
                          );
                        })
                      )
                      
                      } */}

                  {progressBarAccordianListItemUseMemo}
                </ContentContainer>
              )}
            </RootContainer>
          ));
        } else {
          return (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={SsOptLoader} width={"20%"} />
            </div>
          );
        }
      }, [
        isDataLoaded,
        data,
        currentIndex,
        tabIndex,
        searchResults,
        showSearch,
        searchString,
      ])}
    </>
  );
};

export default ListItem;
