// @ts-nocheck
import { useState, useEffect } from "react";
import {
  withGoogleMap,
  GoogleMap,
  GroundOverlay,
  OverlayView,
  Marker,
} from "react-google-maps";
import Grid from "@mui/material/Grid";
import customMapStyles from "./customMapStyles";
import withScriptjs from "react-google-maps/lib/withScriptjs";
import HeatmapLayer from "react-google-maps/lib/components/visualization/HeatmapLayer";
import appData from "../../data/appData";
import SsOptLoader from "../../assets/SS-Opt-loader.gif";
import useStyles from "./styles";

const defaultCenter = {
  lat: 29.426835241100686,
  lng: -98.43705045634116,
};

const fullScreenDefaultCenter = {
  lat: 29.427035241100686,
  lng: -98.43647045634116,
};

const MapCreate = (props: any) => {
  const {
    layout,
    heatMapRadius,
    heatMapOpacity,
    heatMapMaxIntensity,
    heatMapWeight,
    fullScreenActive,
    selectedLevel,
    heatMapData,
    gradientRange,
    pageName
  } = props;
  const {} = useStyles();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getZoomLevel = () => {
    if (windowWidth > 3839) {
      return fullScreenActive ? 17.9 : 16.5;
    } else if (windowWidth > 3071) {
      return fullScreenActive ? 17.7 : 16.2;
    } else if (windowWidth > 2047) {
      return fullScreenActive ? 17 : 15.6;
    } else if (windowWidth > 1919) {
      return fullScreenActive ? 16.7 : 15.35;
    } else if (windowWidth < 1793) {
      return fullScreenActive ? 16.9 : 15.35;
    } else {
      return fullScreenActive ? 16.9 : 15.35;
    }
  };

  const getPoints: any = (data: any) => {
    let latLng: any = [];
    data?.map((value: any, index: any) => {
      latLng?.push({
        location: new window.google.maps.LatLng(value?.lat, value?.lng),
        // weight: 1,
      });
    });
    return latLng;
  };

  const getMapTypeControls = () => {
    const defaultMapOptions = {
      styles: customMapStyles,
    };
    return {
      ...defaultMapOptions,
      mapTypeControl: false,
      rotateControl: false,
      fullscreenControl: false,
      scrollwheel: true,
      streetViewControl: false,
      zoomControl: false,
      scaleControl: false,
      gestureHandling: "none",
    };
  };

  const [isSvgLoaded, setIsSvgLoaded] = useState<boolean>(false);
  const [isMapLoaded, setIsMapLoaded] = useState<boolean>(false);
  const [refValue, setRefValue] = useState<boolean>(false)

  useEffect(() => {
    if(refValue) {
      const timerId = setTimeout(() => {
        setIsMapLoaded(true);
      }, 2000);
  
      return () => clearInterval(timerId);
    }
   
  }, [refValue]);

  useEffect(() => {
    if (isMapLoaded ) {
      const timerId = setTimeout(() => {
        setIsSvgLoaded(true);
      }, 500);

      return () => clearInterval(timerId);
    }
  }, [isMapLoaded]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <GoogleMap
          defaultZoom={getZoomLevel()}
          center={fullScreenActive ? fullScreenDefaultCenter : defaultCenter}
          options={getMapTypeControls()}
          ref={ref => setRefValue(ref)}
        >
          {(isMapLoaded && refValue ) ? (
            <>
              <GroundOverlay
                key={layout}
                url={layout}
                bounds={{
                  north: 29.439035241100686,
                  south: 29.415035241100686,
                  east: -98.4307245634116,
                  west: -98.44207045634116,
                }}
              />

              {isSvgLoaded &&
                heatMapData &&
                heatMapData?.map((value: any) => {
                  return (
                    <HeatmapLayer
                      data={getPoints(value?.dataPoints)}
                      options={{
                        radius: fullScreenActive
                          ? value?.radius * 3
                          : value?.radius,
                        opacity: value?.opacity
                          ? value?.opacity
                          : heatMapOpacity,
                        maxIntensity: 1,
                        gradient: value?.gradient,
                      }}
                    />
                  );
                })}
            </>
          ) : (
            <div
              style={{
                width: pageName === "fullScreenView" ? "7%" : "17%",
                position : "absolute",
                top : pageName === "fullScreenView" ? "35vh" : "10vh",
                left :  pageName === "fullScreenView" ? "36vw" : "9vw"
              }}
            >
              <img src={SsOptLoader} width={"100%"} />
            </div>
          )}
        </GoogleMap>
      </Grid>
    </Grid>
  );
};

const MapWrapped = withScriptjs(withGoogleMap(MapCreate));

export default function BirdsView(props: any) {
  return (
    <MapWrapped
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${appData?.googleApiKey}&v=3.exp&libraries=geometry,drawing,places,visualization`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: "100%" }} />}
      mapElement={<div style={{ height: `100%` }} />}
      {...props}
    />
  );
}
