import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(
  () => ({
    dashboardRootContainer: (props: any) => ({
      height: "100%",
      fontSize: "0.7vw",
      background: props.fullScreenActive ? "#F2F5F5" : "unset",
    }),
    dashboardGridContainer: () => ({
      height: "100%",
      padding: "1%",
      paddingBottom: "0",
      flex: 1,
    }),
    gridItemSection: () => ({
      background: "rgba(242, 245, 245, 1)",
      height: "100%",
      padding: "3%",
      borderRadius: "2%",
    }),
    gridSectionLevelName: () => ({
      background: "rgba(7, 38, 36, 0.7)",
      padding: "1.5% 4%",
      display: "inline-flex",
      color: "white",
      borderRadius: "1vh",
      fontWeight: "600",
      letterSpacing: "0.07vh",
    }),
    gridSectionMapContainer: () => ({
      height: "92%",
      padding: "1%",
      position: "relative",
    }),
    fullScreenIconSection: () => ({
      right: "0vw",
      bottom: "0vh",
      position: "absolute",
      height: "2.5vw",
    }),
    graphCustomSelectDropDown: (props: any) => ({
      // "& .MuiOutlinedInput-notchedOutline" : {
      //     border: "0 !important",
      // },
      minWidth: "100% !important",
      // minheight: "100% !important",
      "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
        {
          // paddingRight: "100% !important",
        },
      // "& .MuiPaper-root": {
      //   border: "0px solid #F3F0FC !important",
      // },
      "& .MuiOutlinedInput-input.Mui-disabled": {
        "-webkit-text-fill-color": "rgb(123 123 123 / 38%)",
      },
      "& .MuiSelect-select": {
        // padding: "10% 11%",
        padding: "6% !important",
        fontFamily: `"Roboto", sans-serif`,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "0.4vw",
      },
      "& .MuiInputBase-root": {
        //    borderRadius: "4px",
        fontSize: "0.75vw",
        fontFamily: `'Noto Sans', sans-serif`,
        // lineHeight: 21,
        textAlign: "left",
        fontWeight: "500",
        position: "relative",
        color: "#ffffff",
        background: "#0B3835",
        width: "100%",
        // paddingLeft: "4%",
        "& .MuiSvgIcon-root": {
          position: "absolute",
          //  right: "1%",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          color: "#F3F0FC",
          border: `0px solid #F95E3C !important`,
        },
      },
      "& .css-t0wjx6-MuiButtonBase-root-MuiMenuItem-root.Mui-selected.MuiMenuItem-root":
        {
          color: "#fff !important",
        },

      "& .MuiSelect-iconOutlined": {
        fontSize: "1.5vw",
        color:  `#ffffff !important`,
      },
    }),

    minimizeScreenIconSection: () => ({
      right: "0.5vw",
      bottom: "8vh",
      position: "absolute",
      width: "3vw",
      cursor: "pointer",
    }),
  }),
  { index: 1 }
);

export default useStyles;
