//@ts-nocheck
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch} from "react-redux";
import { setUserLogin } from "../../redux/actions/loginActions";
import Grid from "@mui/material/Grid";
import DashboardGridContainer from "components/DashboardGridContainer";
import DashboardFullScreen from "components/DashboardFullScreen";
import StadiumLevel1 from "../../assets/StadiumLayout/Level1.svg";
import StadiumLevel2 from "../../assets/StadiumLayout/Level2.svg";
import StadiumLevel3 from "../../assets/StadiumLayout/Level3.svg";
import StadiumLevel4 from "../../assets/StadiumLayout/Level4.svg";
import StadiumLevel5 from "../../assets/StadiumLayout/Level5.svg";
import StadiumLevel6 from "../../assets/StadiumLayout/Level6.svg";
import levelsList from "mockdata/levelsList";
import SsOptLoader from "../../assets/SS-Opt-loader.gif";
import { formattedDataLevel, formattedCombinedData } from "utils/utils";
import { ListItem } from "elements";
import gradientColorList from "data/gradientList";
import useStyles from "./styles";
import PlayerContainer from "components/PlayerContainer";
import DatePicker from "components/DatePicker";
import RightPanelDropdown from "elements/RightPanelDropdown";
import FullScreenListItem from "elements/FullScreenListItem/fullScreenListItem";
import eventsList from "mockdata/eventsList";
import moment from "moment";
import MinimizeIcon from "../../assets/MinimizeIcon.svg";
import Calendar from "elements/Calendar";
// import IdleTimer from 'react-idle-timer';
import { useIdleTimer } from 'react-idle-timer';

interface TimeSlot {
  startTime: string;
  endTime: string;
}

const DashboardContainer = (props: any) => {

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(new Date());

  const [selectedCalendarDate, setSelectedCalendarDate] = useState<string>(
    moment().format("MM-DD-YYYY")
  );

  

  const getTimeSlots = (): TimeSlot[] => {
    // const currentTime = new Date();
    // currentTime.setTime(currentTime.getTime() - 11 * 60 * 60 * 1000); // 11 hours before the current time

    const timeSlots: TimeSlot[] = [];

    const [timeCheckHours, timeCheckAmPm] = new Date()
      .toLocaleString("en-US", { hour: "numeric" })
      .split(" ");
    if (
      (parseInt(timeCheckHours) > 10 && parseInt(timeCheckHours) !== 12 && timeCheckAmPm == "AM") ||
      (parseInt(timeCheckHours) <= 12 && timeCheckAmPm == "PM")
    ) {
      const currentTime = new Date();
      currentTime.setHours(
        currentTime.getHours() -
          (parseInt(timeCheckHours) >= 8 && parseInt(timeCheckHours) !== 12 && timeCheckAmPm == "PM" 
            ? 12
            : parseInt(timeCheckHours) > 4 &&  parseInt(timeCheckHours) <= 7 && timeCheckAmPm == "PM"
            ? 9
            : parseInt(timeCheckHours) > 2 &&  parseInt(timeCheckHours) <= 4 && timeCheckAmPm == "PM"
            ? 7
            : ((parseInt(timeCheckHours) < 12 && parseInt(timeCheckHours) <= 2) || (parseInt(timeCheckHours) === 12)) && timeCheckAmPm == "PM"
            ? 4
            : parseInt(timeCheckHours) === 11 && timeCheckAmPm == "AM" && 3
            // ? 3
            // : parseInt(timeCheckHours) === 9 && timeCheckAmPm == "AM" && 8
            )
      ); // 11 hours before the current time
      
      currentTime.setMinutes(0);
      currentTime.setSeconds(0);

      const totalEventsCount =
          parseInt(timeCheckHours) >= 8 && parseInt(timeCheckHours) !== 12 && timeCheckAmPm == "PM"
          ? 3
          : ((parseInt(timeCheckHours) > 4 &&  parseInt(timeCheckHours) <= 7) || (parseInt(timeCheckHours) > 2 &&  parseInt(timeCheckHours) <= 4)) && timeCheckAmPm == "PM"
          ? 2
          : ((((parseInt(timeCheckHours) < 12 && parseInt(timeCheckHours) <= 2) || (parseInt(timeCheckHours) === 12)) && timeCheckAmPm == "PM") || (parseInt(timeCheckHours) === 11 && timeCheckAmPm == "AM")) && 1
          // ? 1
          // : parseInt(timeCheckHours) === 9 && timeCheckAmPm == "AM" && 1;

          
      for (let i = 0; i < totalEventsCount && totalEventsCount; i++) {
        const startTime = currentTime.toLocaleString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });

        const endTime = new Date(
          currentTime.getTime() + 2 * 60 * 60 * 1000
        ).toLocaleString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });

        timeSlots.push({ startTime, endTime });

        currentTime.setTime(currentTime.getTime() + 3.5 * 60 * 60 * 1000); // Move to the next time slot
      }

      return {timeSlots, totalEventsCount};
    }

    return {timeSlots: null, totalEventsCount: 0};
  };

  

  const getSelectList = () => {    

  if(moment(startDate).format("MM-DD-YYYY") === moment().format("MM-DD-YYYY")){
    const {timeSlots, totalEventsCount} = getTimeSlots();
    const eventsListArrayDummy = Array(totalEventsCount+1).fill("");
    return eventsListArrayDummy.map((event: any, index: any) => ({
      label:
        index === 0 &&
        moment(startDate).format("MM-DD-YYYY") === moment().format("MM-DD-YYYY")
          ? "Live NBA"
          : `NBA${index} : ` + moment(startDate).format("MM-DD-YYYY"),
      value: `${index+1}`,
      start: index >= 1 ? timeSlots?.length > 0 && timeSlots[index-1]?.startTime : "",
      end: index >= 1 ? timeSlots?.length > 0 && timeSlots[index-1]?.endTime : "",
      eventStatus: 
        index === 0 &&
        moment(startDate).format("MM-DD-YYYY") ===
          moment().format("MM-DD-YYYY"),
    }));
  }

    return eventsList.map((event: any, index: any) => ({
      ...event,
      label:
        index === 0 &&
        moment(startDate).format("MM-DD-YYYY") === moment().format("MM-DD-YYYY")
          ? "Live NBA"
          : event.event + moment(startDate).format("MM-DD-YYYY"),
      value: event.id,
      eventStatus:
        index === 0 &&
        moment(startDate).format("MM-DD-YYYY") ===
          moment().format("MM-DD-YYYY"),
    }));
  };




  
  const { fullScreenActive, setFullScreenActive } = props;

  const [playLiveEvent, setPlayLiveEvent] = useState<boolean>(true);
  const [playerControlIsDisable, setPlayerControlIsDisable] =
    useState<boolean>(true);
  const { dashboardRootContainer, graphCustomSelectDropDown, minimizeScreenIconSection } = useStyles({
    fullScreenActive: fullScreenActive,
    playLiveEvent: playLiveEvent,
  });
  const [selectedLevel, setSelectedLevel] = useState();
  const [heatMapWeight, setHeatMapWeight] = useState(2);
  const [heatMapRadius, setHeatMapRadius] = useState(53);
  const [heatMapOpacity, setHeatMapOpacity] = useState(0.8);
  const [heatMapMaxIntensity, setHeatMapMaxIntensity] = useState(0.5);
  const [mapLayout, setMapLayout] = useState<any>();

  const [data1, setData1] = useState(formattedCombinedData(levelsList).level1);
  const [data2, setData2] = useState(formattedCombinedData(levelsList).level2);
  const [data3, setData3] = useState(formattedCombinedData(levelsList).level3);
  const [data4, setData4] = useState(formattedCombinedData(levelsList).level4);
  const [data5, setData5] = useState(formattedCombinedData(levelsList).level5);
  const [data6, setData6] = useState(formattedCombinedData(levelsList).level6);

  const [heatMapDataPointsLevel1, setHeatMapDataPointsLevel1] = useState<any>();
  const [heatMapDataPointsLevel2, setHeatMapDataPointsLevel2] = useState<any>();
  const [heatMapDataPointsLevel3, setHeatMapDataPointsLevel3] = useState<any>();
  const [heatMapDataPointsLevel4, setHeatMapDataPointsLevel4] = useState<any>();
  const [heatMapDataPointsLevel5, setHeatMapDataPointsLevel5] = useState<any>();
  const [heatMapDataPointsLevel6, setHeatMapDataPointsLevel6] = useState<any>();
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const [manuallySlidedValue, setManuallySlidedValue] = useState<number | null>(
    null
  );
  const [manuallySlidedValueOnLiveEvent, setManuallySlidedValueOnLiveEvent] =
    useState<number | null>(null);
  const [selectedRightObject, setSelectedRightObject] = useState<any>(
    getSelectList()[0]
  );
  const [isManuallyClicked, setIsManuallyClicked] = useState<boolean>(false);
  const [isForwarded, setIsForwarded] = useState<boolean>(false);
  const [prevCurrentTime, setPrevCurrentTime] = useState<number>(0);
  const [playbackSpeed, setPlaybackSpeed] = useState<number>(1);
  const [manuallySlidedResult, setManuallySlidedResult] = useState<
    number | null
  >(manuallySlidedValue && manuallySlidedValue - prevCurrentTime);

  const [isOpen, setIsOpen] = useState(false);

  const [playerTimer, setPlayerTimer] = useState<any>({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [currentTime, setCurrentTime] = useState<number>(0);

  useEffect(() => {
    const seconds = currentTime;
    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 3600;

    const minutes = Math.floor(remainingSeconds / 60);
    const finalSeconds = remainingSeconds % 60;
    setPlayerTimer({ hours: hours, minutes: minutes, seconds: finalSeconds });
  }, [currentTime]);

  const getCurrentPlayerTime = (time: any) => {
    setIsForwarded(() =>
      manuallySlidedValue && manuallySlidedValue > prevCurrentTime
        ? true
        : false
    );
  };

  const getDataPrevValue = (
    prevData: any,
    levelData: any,
    playerTimer: any
  ) => {
    return prevData.map((item: any, index: any) => {
      let initialCount = levelData?.[index]?.count;
      let initialRadius = levelData?.[index]?.radius;
      let maxValue = 90;
      let newCount = initialCount;

      // if (item?.count > initialCount && item?.count> maxValue ) {
      //   newCount = playerTimer?.seconds + initialCount;
      // } else if (newCount > maxValue) {
      //   newCount = Math.max(item?.count - playbackSpeed, 0);
      // }

      return {
        ...item,
        // count: parseInt( item?.count >
        //   playerTimer?.seconds + initialCount > 90
        //     ? item?.count - playbackSpeed
        //     : playerTimer?.seconds + initialCount
        // ),

        count: Math.abs(parseInt(
          playerTimer?.seconds + initialCount > 90
            ? initialCount
            : playerTimer?.seconds + initialCount
        )),
        // count: Math.abs(parseInt(newCount.toString())),

        dataPoints: item?.location,
        radius:
          playerTimer?.hours % 2 === 0
            ? initialRadius +
              parseInt(
                (playerTimer?.minutes + 1) / (playerTimer?.hours + 2) / 2
              )
            : playerTimer?.hours % 2 !== 0
            ? initialRadius +
              initialRadius / 2 -
              parseInt(
                (playerTimer?.minutes + 1) / (playerTimer?.hours + 1) / 2
              )
            : initialRadius,
        gradient: getGradientRange(item?.count),
      };
    });
  };

  const increaseCount = () => {
    setData1((prevData) =>
      getDataPrevValue(
        prevData,
        formattedCombinedData(levelsList).level1,
        playerTimer
      )
    );
    setData2((prevData) =>
      getDataPrevValue(
        prevData,
        formattedCombinedData(levelsList).level2,
        playerTimer
      )
    );
    setData3((prevData) =>
      getDataPrevValue(
        prevData,
        formattedCombinedData(levelsList).level3,
        playerTimer
      )
    );
    setData4((prevData) =>
      getDataPrevValue(
        prevData,
        formattedCombinedData(levelsList).level4,
        playerTimer
      )
    );
    setData5((prevData) =>
      getDataPrevValue(
        prevData,
        formattedCombinedData(levelsList).level5,
        playerTimer
      )
    );
    setData6((prevData) =>
      getDataPrevValue(
        prevData,
        formattedCombinedData(levelsList).level6,
        playerTimer
      )
    );
  };

  // const [updatedLevelList, setUpdatedLevelList] = useState(
  //   formattedDataLevel({
  //     level1: data1,
  //     level2: data2,
  //     level3: data3,
  //     level4: data4,
  //     level5: data5,
  //     level6: data6,
  //   })
  // );

  const dataObj = useMemo(
    () => ({
      level1: data1,
      level2: data2,
      level3: data3,
      level4: data4,
      level5: data5,
      level6: data6,
    }),
    [data1, data2, data3, data4, data5, data6]
  );

  const updatedLevelList = useMemo(() => formattedDataLevel(dataObj), [dataObj]);


  useEffect(() => {
    if (isPlaying) {
      increaseCount();
      // setHeatMapDataPointsLevel1(data1);
      // setHeatMapDataPointsLevel2(data2);
      // setHeatMapDataPointsLevel3(data3);
      // setHeatMapDataPointsLevel4(data4);
      // setHeatMapDataPointsLevel5(data5);
      // setHeatMapDataPointsLevel6(data6);
      // let dataObj = {
      //   level1: data1,
      //   level2: data2,
      //   level3: data3,
      //   level4: data4,
      //   level5: data5,
      //   level6: data6,
      // };
      // setUpdatedLevelList(formattedDataLevel(dataObj));
    }
  }, [
    isPlaying,
    fullScreenActive,
    selectedLevel,
    heatMapRadius,
    isManuallyClicked,
    playbackSpeed,
    currentTime,
    // playerTimer,
  ]);


  const [heatMapData, setHeatMapData] = useState<any>();

  const getSelectedLevelDetails = (number: any) => {
    switch (number) {
      case 1:
        setMapLayout(StadiumLevel1);
        setHeatMapData(data1);
        break;
      case 2:
        setMapLayout(StadiumLevel2);
        setHeatMapData(data2);
        break;
      case 3:
        setMapLayout(StadiumLevel3);
        setHeatMapData(data3);
        break;
      case 4:
        setMapLayout(StadiumLevel4);
        setHeatMapData(data4);
        break;
      case 5:
        setMapLayout(StadiumLevel5);
        setHeatMapData(data5);
        break;
      case 6:
        setMapLayout(StadiumLevel6);
        setHeatMapData(data6);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (fullScreenActive) {
      getSelectedLevelDetails(selectedLevel);
    }
  }, [
    selectedLevel,
    fullScreenActive,
    heatMapRadius,
    isPlaying,
    data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    isManuallyClicked,
  ]);

  // useEffect(() => {
  //   if (fullScreenActive) {
  //     getSelectedLevelDetails(selectedLevel);
  //   }
  // }, [selectedLevel, fullScreenActive]);

  const inRange = (num: any, min: any, max: any) => num >= min && num <= max;

  const [gradientRange, setGradientRange] = useState<any>();

  const getGradientRange = (val: any) => {
    let gradientRange;
    const range1 = inRange(val, 0, 25);
    const range2 = inRange(val, 26, 50);
    const range3 = inRange(val, 51, 75);
    const range4 = inRange(val, 76, 80);
    const range5 = inRange(val, 81, 100);
    if (range1) {
      gradientRange = gradientColorList?.gradientSet1;
    } else if (range2) {
      gradientRange = gradientColorList?.gradientSet2;
    } else if (range3) {
      gradientRange = gradientColorList?.gradientSet3;
    } else if (range4) {
      gradientRange = gradientColorList?.gradientSet4;
    } else if (range5) {
      gradientRange = gradientColorList?.gradientSet5;
    }
    setGradientRange(gradientRange);
    return gradientRange;
  };

  const onHandleFullScreen = (number: any) => {
    setSelectedLevel(number);
    setFullScreenActive(true);
    getSelectedLevelDetails(number);
    setIsOpen(false);
  };

  const onHandleMinimize = () => {
    setFullScreenActive(false);
    setIsOpen(false);
  };

  const [defaultSelectedValue, setDefaultSelectedValue] = useState<any>("");

  const getDateRange = (startDate: string) => {
    setSelectedCalendarDate(startDate);
  };

  const [selectedValueOfRightPanelDropdown, setSelectedValueOfRightPanelDropdown] = useState(
     defaultSelectedValue ? defaultSelectedValue : getSelectList()?.length > 0 && getSelectList()[0]?.label
  );

  useEffect(() => {
    
      setSelectedValueOfRightPanelDropdown( defaultSelectedValue ? defaultSelectedValue : getSelectList()?.length > 0 && getSelectList()[0]?.label);
    
  }, [defaultSelectedValue]);

  const selectList = [
    { label: "NBA - 04-10-2023", value: "NBA - 04-10-2023" },
    { label: "NBA - 03-18-2023", value: "NBA - 03-18-2023" },
    { label: "NBA - 03-03-2023", value: "NBA - 03-03-2023" },
    { label: "NBA - 02-23-2023", value: "NBA - 02-23-2023" },
    { label: "NBA - 01-10-2023", value: "NBA - 01-10-2023" },
  ];

  // const [selectedDate, setSelectedDate] = useState<string>(eventsList[0].id);
  const handleSelect = (selectedDropDownValue: string) => {
    const events = getSelectList();
    // setSelectedDate(selectedDropDownValue);
    setSelectedRightObject(
      events.find((event) => event.label === selectedDropDownValue)
    );
  };


  useEffect(() => {
    setDefaultSelectedValue(
      moment(startDate).format("MM-DD-YYYY") === moment().format("MM-DD-YYYY")
        ? "Live NBA"
        : `NBA1 : ${moment(startDate).format("MM-DD-YYYY")}`
    );

    setSelectedRightObject(getSelectList()[0]);
  }, [startDate]);

  useEffect(() => {  
    setPlayLiveEvent(selectedRightObject?.eventStatus);
    setPlaybackSpeed(1)
    setManuallySlidedValueOnLiveEvent(null);
    if (!selectedRightObject?.eventStatus) {
      setCurrentTime(0);
    }
    
  }, [selectedRightObject]);

  const isInitialRender = useRef(true);

  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(
    isInitialRender.current ? true : false
  );

  useEffect(() => {
    if (isInitialRender.current) {
      // Skip the logic for the initial render
      isInitialRender.current = false;
    } else {
      setIsDataLoaded(false);
      setTimeout(() => {
        setIsDataLoaded(true);
      }, 500);
    }
  }, [fullScreenActive]);

  const dashboardFullScreenUseMemo = useMemo(()=>(
    <DashboardFullScreen
                heatMapRadius={heatMapRadius}
                heatMapOpacity={heatMapOpacity}
                heatMapMaxIntensity={heatMapMaxIntensity}
                heatMapWeight={heatMapWeight}
                fullScreenActive={fullScreenActive}
                setFullScreenActive={setFullScreenActive}
                selectedLevel={selectedLevel}
                mapLayout={mapLayout}
                heatMapData={heatMapData}
                gradientRange={gradientRange}
                onHandleMinimize={onHandleMinimize}
                playLiveEvent={playLiveEvent}
              />
  ),[
    heatMapRadius,
    heatMapOpacity,
    heatMapMaxIntensity,
    heatMapWeight,
    fullScreenActive,
    selectedLevel,
    mapLayout,
    heatMapData,
    gradientRange,
    onHandleMinimize,
    playLiveEvent,
  ])

  const dashboardGridContainerUseMemo = useMemo(()=>(
    <DashboardGridContainer
                  heatMapRadius={heatMapRadius}
                  heatMapOpacity={heatMapOpacity}
                  heatMapMaxIntensity={heatMapMaxIntensity}
                  heatMapWeight={heatMapWeight}
                  onHandleFullScreen={onHandleFullScreen}
                  fullScreenActive={fullScreenActive}
                  selectedLevel={selectedLevel}
                  heatMapDataSetLevel1={data1}
                  heatMapDataSetLevel2={data2}
                  heatMapDataSetLevel3={data3}
                  heatMapDataSetLevel4={data4}
                  heatMapDataSetLevel5={data5}
                  heatMapDataSetLevel6={data6}
                  setHeatMapData={setHeatMapData}
                  gradientRange={gradientRange}
                  playLiveEvent={playLiveEvent}
                />
  ),[
    heatMapRadius,
    heatMapOpacity,
    heatMapMaxIntensity,
    heatMapWeight,
    onHandleFullScreen,
    fullScreenActive,
    selectedLevel,
    data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    setHeatMapData,
    gradientRange,
    playLiveEvent,
  ])

  const playerContainerUseMemo = useMemo(()=>(
    <PlayerContainer
                  isPlaying={isPlaying}
                  setIsPlaying={setIsPlaying}
                  manuallySlidedValue={manuallySlidedValue}
                  setManuallySlidedValue={setManuallySlidedValue}
                  setManuallySlidedValueOnLiveEvent={
                    setManuallySlidedValueOnLiveEvent
                  }
                  manuallySlidedValueOnLiveEvent={
                    manuallySlidedValueOnLiveEvent
                  }
                  setIsManuallyClicked={setIsManuallyClicked}
                  getCurrentPlayerTime={getCurrentPlayerTime}
                  // prevCurrentTim={prevCurrentTime}
                  setPrevCurrentTime={setPrevCurrentTime}
                  playbackSpeed={playbackSpeed}
                  setPlaybackSpeed={setPlaybackSpeed}
                  fullScreenActive={fullScreenActive}
                  // setPlayerTimer={setPlayerTimer}
                  isManuallyClicked={isManuallyClicked}
                  currentTime={currentTime}
                  setCurrentTime={setCurrentTime}
                  selectedRightObject={selectedRightObject}
                  playLiveEvent={playLiveEvent}
                  setPlayLiveEvent={setPlayLiveEvent}
                  setPlayerControlIsDisable={setPlayerControlIsDisable}
                  playerControlIsDisable={playerControlIsDisable}
                />
  ),[
    isPlaying,
    setIsPlaying,
    manuallySlidedValue,
    setManuallySlidedValue,
    setManuallySlidedValueOnLiveEvent,
    manuallySlidedValueOnLiveEvent,
    setIsManuallyClicked,
    getCurrentPlayerTime,
    // prevCurrentTime,
    setPrevCurrentTime,
    playbackSpeed,
    setPlaybackSpeed,
    fullScreenActive,
    // setPlayerTimer,
    isManuallyClicked,
    currentTime,
    setCurrentTime,
    selectedRightObject,
    playLiveEvent,
    setPlayLiveEvent,
    setPlayerControlIsDisable,
    playerControlIsDisable,
  ])

  const calendarUseMemo = useMemo(()=>(
    <Calendar startDate={startDate} setStartDate={setStartDate} />
  ),[
    startDate, setStartDate
  ])

  const rightPanelDropdownUseMemo = useMemo(()=>(
    <RightPanelDropdown
                  defaultSelectedValue={defaultSelectedValue}
                  selectList={getSelectList()}
                  handleSelect={handleSelect}
                  customWidth={"100%"}
                  customHeight={"100%"}
                  customSelectCustom={graphCustomSelectDropDown}
                  drowpDownTextColor={"#fff"}
                  dropDownBgColor={"#0B3835"}
                  dropDownSelectedBgColor={"#072624"}
                  dropDownSelectedTextColor={"#FFF"}
                  selectedValueOfRightPanelDropdown={selectedValueOfRightPanelDropdown} 
                  setSelectedValueOfRightPanelDropdown={setSelectedValueOfRightPanelDropdown}
                  // disabled={playLiveEvent}
                />
  ),[
    defaultSelectedValue,
    getSelectList,
    handleSelect,
    graphCustomSelectDropDown,
    selectedValueOfRightPanelDropdown,
    setSelectedValueOfRightPanelDropdown,
  ])

  const fullScreenListItemUseMemo = useMemo(()=>(
    <FullScreenListItem
                  data={updatedLevelList?.find(
                    (item: any) => item?.id === selectedLevel
                  )}
                  selectedRightObject={selectedRightObject}
                  // data={levelsList?.find((item: any) => item?.id === selectedLevel)}
                />
  ),[
    updatedLevelList, selectedLevel, selectedRightObject
  ])

  const listItemUseMemo = useMemo(()=>(
    <ListItem
                  selectedRightObject={selectedRightObject}
                  data={updatedLevelList && updatedLevelList}
                  // data={levelsList}
                />
  ),[
    selectedRightObject, updatedLevelList
  ])

  const fullScreenPlayerContainerUseMemo = useMemo(()=>(
    <PlayerContainer
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                manuallySlidedValue={manuallySlidedValue}
                setManuallySlidedValue={setManuallySlidedValue}
                setIsManuallyClicked={setIsManuallyClicked}
                getCurrentPlayerTime={getCurrentPlayerTime}
                prevCurrentTim={prevCurrentTime}
                setPrevCurrentTime={setPrevCurrentTime}
                playbackSpeed={playbackSpeed}
                setPlaybackSpeed={setPlaybackSpeed}
                fullScreenActive={fullScreenActive}
                setPlayerTimer={setPlayerTimer}
                isManuallyClicked={isManuallyClicked}
                currentTime={currentTime}
                setCurrentTime={setCurrentTime}
                selectedRightObject={selectedRightObject}
                setManuallySlidedValueOnLiveEvent={
                  setManuallySlidedValueOnLiveEvent
                }
                manuallySlidedValueOnLiveEvent={manuallySlidedValueOnLiveEvent}
                playLiveEvent={playLiveEvent}
                setPlayLiveEvent={setPlayLiveEvent}
                setPlayerControlIsDisable={setPlayerControlIsDisable}
                playerControlIsDisable={playerControlIsDisable}
              />
  ),[
    isPlaying,
    setIsPlaying,
    manuallySlidedValue,
    setManuallySlidedValue,
    setIsManuallyClicked,
    getCurrentPlayerTime,
    prevCurrentTime,
    setPrevCurrentTime,
    playbackSpeed,
    setPlaybackSpeed,
    fullScreenActive,
    setPlayerTimer,
    isManuallyClicked,
    currentTime,
    setCurrentTime,
    selectedRightObject,
    setManuallySlidedValueOnLiveEvent,
    manuallySlidedValueOnLiveEvent,
    playLiveEvent,
    setPlayLiveEvent,
    setPlayerControlIsDisable,
    playerControlIsDisable,
  ])

  const handleOnIdle = () => {
    // SHOW YOUR MODAL HERE AND LoGOUT
    console.log('The user has been logged out...');
    // window.location = '/';

    localStorage.removeItem("user");
      localStorage.clear();
      dispatch(setUserLogin({}));
      navigate("/login");
  };

  const { getRemainingTime } = useIdleTimer({
    timeout:  10 * 60 * 1000,
    onIdle: handleOnIdle
  });

 

  return (
    <>
    <Grid container xs={12} className={dashboardRootContainer}>
      {isDataLoaded ? (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            style={{
              display: "flex",
              flexDirection: "column",
              height: fullScreenActive ? "90%" : "100%",
            }}
          >
            {fullScreenActive ? (
              dashboardFullScreenUseMemo
            ) : (
              <>
                {dashboardGridContainerUseMemo}
                {playerContainerUseMemo}
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            style={{
              background: fullScreenActive ? "#F2F5F5" : "rgba(15, 24, 24, 1)",
              height: fullScreenActive ? "90%" : "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "3% 3% 0 3%",
              }}
            >
              <div style={{ flex: 1, marginRight: "3%" }}>
                {/* <DatePicker dateRange={getDateRange} isOpen={isOpen} setIsOpen={setIsOpen} /> */}
                {calendarUseMemo}
              </div>
              <div style={{ flex: 1 }}>
                {rightPanelDropdownUseMemo}
                {/* <Calendar /> */}
              </div>
            </div>
            <div
              style={{
                height: fullScreenActive ? "95%" : "89%",
                margin: fullScreenActive ? "inherit" : "3% 0",
                overflow: fullScreenActive ? "inherit" : "auto",
              }}
            >
              {/* <ListItem /> */}
              {fullScreenActive ? (
                fullScreenListItemUseMemo
              ) : (
                listItemUseMemo
              )}
            </div>
          </Grid>
          {fullScreenActive && (
            <Grid item xs={12}>
              {fullScreenPlayerContainerUseMemo}
               <Grid className={minimizeScreenIconSection}>
                <img
                  src={MinimizeIcon}
                  alt="MinimizeIcon"
                  width={"100%"}
                  height={"100%"}
                  onClick={onHandleMinimize}
                />
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={SsOptLoader} width={"10%"} />
        </div>
      )}
    </Grid>
    
    </>
  );
};

export default DashboardContainer;
