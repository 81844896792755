  //1 - 25
  const gradientColorList = {


  
  gradientSet1 : [
    "rgba(216, 177, 255, 0)",
    "rgb(172,184,238)",
    "rgb(172,184,238)",
    "rgb(172,184,238)",
    "rgb(172,184,238)",
  ],

  //26 - 50
  gradientSet2 : [
    "rgba(216, 177, 255, 0)",
    "rgb(172,184,238)",
    "rgba(102, 255, 0, 1)",
    "rgba(102, 255, 0, 1)",
    "rgba(147, 255, 0, 1)",
  ],

  //51 - 75
  gradientSet3 : [
    "rgba(216, 177, 255, 0)",
    "rgb(172,184,238)",
    "rgba(102, 255, 0, 1)",
    "rgba(102, 255, 0, 1)",
    "rgba(147, 255, 0, 1)",
    "rgba(193, 255, 0, 1)",
    "rgba(238, 255, 0, 1)",
    "rgba(238, 255, 0, 1)",
    "rgba(244, 227, 0, 1)",
  ],

  //76 - 80
  gradientSet4 : [
    "rgba(216, 177, 255, 0)",
    "rgb(172,184,238)",
    "rgba(102, 255, 0, 1)",
    "rgba(102, 255, 0, 1)",
    "rgba(147, 255, 0, 1)",
    "rgba(193, 255, 0, 1)",
    "rgba(238, 255, 0, 1)",
    "rgba(244, 227, 0, 1)",
    "rgba(249, 198, 0, 1)",
    "rgba(255, 170, 0, 1)",
    "rgba(255, 170, 0, 1)",
    "rgba(255, 170, 0, 1)",
    "rgba(255, 170, 0, 1)",
  ],

  //81 - 100
  gradientSet5 : [
    "rgba(216, 177, 255, 0)",
    "rgb(172,184,238)",
    "rgba(102, 255, 0, 1)",
    "rgba(102, 255, 0, 1)",
    "rgba(147, 255, 0, 1)",
    "rgba(193, 255, 0, 1)",
    "rgba(238, 255, 0, 1)",
    "rgba(244, 227, 0, 1)",
    "rgba(249, 198, 0, 1)",
    "rgba(255, 170, 0, 1)",
    "rgba(255, 113, 0, 1)",
    "rgba(255, 57, 0, 1)",
    "rgba(255, 0, 0, 1)",
    "rgba(255, 0, 0, 1)",
    "rgba(255, 0, 0, 1)",
    "rgba(255, 0, 0, 1)",
  ]
}

  export default gradientColorList