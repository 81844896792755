import React, { useEffect, useState, useMemo } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { createTheme, MenuProps, ThemeProvider } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useStyles from "./styles";

interface CustomMenuProps extends Partial<MenuProps> {
  getContentAnchorEl?: null;
}

const INF_Select: React.FC<any> = (props) => {
  const { customSelect } = useStyles();

  const {
    selectList,
    handleSelect,
    customWidth,
    customHeight,
    disabled,
    customSelectCustom,
    drowpDownTextColor,
    dropDownBgColor,
    dropDownSelectedBgColor,
    dropDownSelectedTextColor,
    initialValue,
  } = props;

  const [selectedValue, setselectedValue] = useState(
    selectList && initialValue ? initialValue : selectList[0]?.value
  );

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleChange = (e: any) => {
    setselectedValue(e.target.value);
    handleSelect(e.target.value);
  };

  useEffect(() => {
    if (selectList && selectList[0] && selectList[0].value !== 1) {
      setselectedValue(selectList && initialValue ? initialValue : selectList[0]?.value);
    }
  }, [initialValue]);

  const handleIsOpen = (event: any)=>{
    setIsOpen(true)
  }

  const handleIsClose = (event: any)=>{
    setIsOpen(false)
  }

  

  return (
    <>
      <ThemeProvider
        theme={createTheme({
          palette: {
            primary: {
              main: dropDownBgColor ? dropDownBgColor : "#000",
            },
          },

          components: {
            MuiPaper: {
              styleOverrides: {
                root: {
                  //  top : "66vh !important",
                  marginTop: "-0.2% !important",
                  backgroundColor: dropDownBgColor ? dropDownBgColor : "#000",
                  borderRadius: "6px !important",
                },
              },
            },
            MuiInputBase: {
              styleOverrides: {
                root: {
                  background: isOpen ? "#8A3D2C" : "#F95E3C",
                }
              }
            },
            MuiMenuItem: {
              styleOverrides: {
                root: {
                  color: drowpDownTextColor ? drowpDownTextColor : "fff",
                  backgroundColor: dropDownBgColor ? dropDownBgColor : "#000",
                  
                  fontSize: "1vw",
                  

                  "&.MuiMenuItem-root": {
                    margin: "0 6%",
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: "10%",
                    marginBottom: "14%",
                    borderRadius: "5px",

                    
                  },

                  "&:last-child": {
                    marginBottom: 0,
                  },

                  "&.Mui-selected": {
                    "&.MuiMenuItem-root": {
                      backgroundColor: dropDownSelectedBgColor
                        ? dropDownSelectedBgColor
                        : "#fff",
                      color: dropDownSelectedTextColor
                        ? dropDownSelectedTextColor
                        : "#fff",
                    },

                    // "&.Mui-focusVisible": { background: "orange" },
                  },
                  "&:hover": {
                    backgroundColor: dropDownBgColor ? dropDownBgColor : "#000",
                  },
                },
              },
            },
           
          },
        })}
      >
        <FormControl
          className={customSelectCustom ? customSelectCustom : customSelect}
          style={{
            width: customWidth,
            height: customHeight,
          }}
        >
          <Select
            name="select"
            MenuProps={
              {
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },

                getContentAnchorEl: null,
              } as CustomMenuProps
            }
            value={selectedValue ? selectedValue : ""}
            onChange={handleChange}
            onOpen={handleIsOpen}
            onClose={handleIsClose}
            disabled={disabled}
            displayEmpty
            renderValue={() => selectedValue}
            IconComponent={ExpandMoreIcon} //CustomSvgIcon
            sx={{
              height: customHeight,
              width: customWidth,
            }}
          >
            {selectList &&
              selectList?.length > 0 &&
              selectList?.map((item: any, index: any) => (
                <MenuItem key={index} value={item.label}>
                  {item.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </ThemeProvider>
    </>
  );
};
export default INF_Select;
