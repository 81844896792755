import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(
  () => ({
    calendarMainLayout: {
      "& .MuiTextField-root": {},
      "& .MuiSvgIcon-root": {
        fill: "#FFFFFF",
        fontSize: "1vw",
      },
      "& .MuiOutlinedInput-input": {
        color: "white",
        fontSize: "0.85vw",
        padding: "6%",
      },
      "& .MuiInputBase-input-MuiOutlinedInput-input": {
        color: "white",
      },
      "& .MuiInputBase-root": {
        background: "#0B3835",
      },
      "& .MuiInputBase-root.Mui-focused": {
        outline: "none !important",
        border: "none !important",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },
    },
    calendarInnerLayout: {},
  }),
  { index: 1 }
);
export default useStyles;
