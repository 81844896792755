import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(
  () => ({
    inputStyle: (props: any) => ({
      background: "#4E4E4B",
      // border: '1px solid #82CFD0',
      // borderRadius: "8px",
      height: "8% !important",
      width: "100%",
      outline: "none",
      transition: "background 450ms ease-in",
      "-webkit-appearance": "none",
      position: "relative",

      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: `${(props.value / props.max) * 100}%`,
        background: "#F95E3C",
        borderTop: "1px solid #F95E3C",
        borderBottom: "1px solid #F95E3C",
        // borderRadius: "8px",
      },

      "&::-webkit-slider-thumb": {
        "-webkit-appearance": "none",
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        background: "#F95E3C",
        cursor: "pointer",
        // marginTop: '-4.5px',
        "&:hover": {
          // Add your styles for the hover state here
          boxShadow: "0 0 5px rgba(249, 94, 60, 0.7)",
        },
      },

      // '&::after': {
      //   content: `'${props.value}'`,
      //   position: 'absolute',
      //   top: '-28px',
      //   left: `${(props.value / props.max) * 100}%`,
      //   transform: 'translateX(-50%)',
      //   background: '#82CFD0',
      //   color: '#fff',
      //   padding: '4px 8px',
      //   borderRadius: '4px',
      // },

      // '&::-webkit-slider-runnable-track': {
      //   background: '#82CFD0',
      //   borderRadius: '8px',
      //   height: '7px',
      // },
    }),

    rangeVale: (props: any) => ({
      position: "absolute",
      minWidth: props?.fullScreenActive ? "9.9%" : "13.5%",
      // width: "19%",
      top: props?.fullScreenActive ? "-1.9vw" : "-1.7vw",
      left: `${(props.value / props.max) * 100}%`,
      transform: "translateX(-50%)",
      background: "#F95E3C",
      color: "#fff",
      padding: "0.5% 0.5%",
      borderRadius: "4px",
      fontSize: "0.8vw",
      zIndex: 10000,

      "&::before": {
        content: '""',
        position: "absolute",
        width: 0,
        height: 0,
        borderTop: "1vh solid #F95E3C",
        borderLeft: "0.7vh solid transparent",
        borderRight: "0.7vh solid transparent",
        top: "100%",
        left: "50%",
        marginTop: "-1%",
      },
    }),

    underlay: (props: any) => ({
      position: "absolute",
      paddingLeft: "0.2%",
      height: "12%",
      width: "100%",
      top: "50%",
      left: 0,
      // zIndex: "1",
      display: "flex",
    }),

    breakpoint: (props: any) => ({
      position: "relative",
      width: "Calc(100%/4)",
      color: "#fff",
      fontSize: "0.6vw",
      borderLeft: "0.6px solid #fff",

      "&:last-child": {
        borderRight: "0.6px solid #fff",
        display: "flex",
        justifyContent: "space-around",
      },
    }),

    breakpointValueStyle: (props: any) => ({
      position: "absolute",
      left: "-6%",
      top: "180%",
    }),

    breakpointLastValueStyle: (props: any) => ({
      position: "absolute",
      right: "-6%",
      top: "180%",
    }),

    graphCustomSelectDropDown: (props:any) => ({
      // "& .MuiOutlinedInput-notchedOutline" : {
      //     border: "0 !important",
      // },
      minWidth: "100% !important",

      "& .css-31bog0-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-disabled":{
        cursor: "not-allowed"
      },
      "& .css-q9qgoj-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":{
        cursor: "not-allowed"
      },
      // minheight: "100% !important",
      "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
        {
          // paddingRight: "100% !important",
        },
      // "& .MuiPaper-root": {
      //   border: "0px solid #F3F0FC !important",
      // },
      "& .MuiSelect-root":{
          "& .Mui-disabled":{
            cursor: "not-allowed !important"
          },
      },
      "& .MuiSelect-select": {
        padding: "10% 11%",
        
      },
      "& .MuiInputBase-root": {
        //    borderRadius: "4px",
        fontSize: "1vw",
        //   fontFamily: `'Nunito Sans', sans-serif !important`,
        // lineHeight: 21,
        textAlign: "left",
        fontWeight: "bold",
        position: "relative",
        color: "#ffffff",

        width: "100%",
        paddingLeft: "4%",
        "& .MuiSvgIcon-root": {
          position: "absolute",
          //  right: "1%",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          // color: "#F3F0FC",
          border: `0px solid #F95E3C !important`,
        },
      },
      "& .css-t0wjx6-MuiButtonBase-root-MuiMenuItem-root.Mui-selected.MuiMenuItem-root":
        {
          color: "#fff !important",
        },

      "& .MuiSelect-iconOutlined": {
        fontSize: "1.5vw",
        color: props?.playLiveEvent && props?.playerControlIsDisable ? "" : `#ffffff !important`,
      },
    }),
  }),
  { index: 1 }
);
export default useStyles;
