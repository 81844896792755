import { useState, useEffect } from "react";
import Map from "components/Map";
import DashboardContainer from "components/DashboardContainer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import theme from "../../theme/theme";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import * as Yup from "yup";
import llaLogo from "../../assets/login_logo.svg";
import { OutlinedInput } from "@mui/material";
import Button from "@mui/material/Button";
import useStyles from "./styles";
import EyeOff from "../../assets/lock.svg";
import OpenEyeIcon from "../../assets/lock.svg";
import AttherateIcon from "../../assets/atthirate.svg";
import { getUserLogin } from "../../redux/actions/loginActions";
import { copyFileSync } from "fs";

const AdminPanelLogin = () => {
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);
  const [showPassword, setShowPassword] = useState(false);

  const [inCorrectCredentials, setInCorrectCredentials] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.login.loginData);

  const {
    loginBannerSection,
    loginFormSection,
    welcomeSection,
    welcomeContent,
    inputTitle,
    inputField,
    inputFieldPassword,
    loginButton,
    innerForm,
    outlineInputField,
    llaLogoSection,
    formSection,
    eyeOff,
    atttherate,
    forgotPasswordSection,
    forgotPassword,
    loginPage,
    copyrights,
    incorrectCredential,
    formikErrorClass,
  } = useStyles(appTheme);

  useEffect(() => {
    console.log("user", user);
    if (
      user &&
      (user?.userName === "florence.paul"  && user?.password === "FP@2023#") ||
        (user?.userName === "john.smith" && user?.password === "JS@2023#")
      
    ) {
      localStorage.setItem("user", JSON.stringify({ role: "ADMIN" }));
      navigate("/dashboard");
    }
  }, [user]);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    initialValues: {
      userid: "",
      password: "",
    },
    validationSchema: Yup.object({
      userid: Yup.string()
        .min(2, "Mininum 2 characters")
        .required("Please Enter Username"),
      password: Yup.string()
        .min(2, "Mininum 2 characters")
        .max(20, "Maximum 20 characters")
        .required("Please Enter Password"),
    }),
    onSubmit: (values) => {
      if (
       (values?.userid === "florence.paul"  && values?.password === "FP@2023#") ||
        (values?.userid === "john.smith" && values?.password === "JS@2023#")
      ) {
        // localStorage.setItem("user", JSON.stringify({ role: "ADMIN" }));
        // navigate("/dashboard");
        let payload = {
          userName: values.userid,
          passWord: values.password,
        };

        dispatch(getUserLogin(payload));
        setInCorrectCredentials(false);
      } else {
        setInCorrectCredentials(true);
      }
    },
  });

  return (
    <>
      <div className={loginPage}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={loginBannerSection}>
            <div>
              <div className={formSection}>
                <div className={llaLogoSection}>
                  <img src={llaLogo} />
                </div>
              </div>
              <div className={loginFormSection}>
                <Grid item xs={12} className={innerForm}>
                  <Box>
                    <form onSubmit={formik.handleSubmit}>
                      <div className={welcomeSection}>
                        <p className={welcomeContent}>Welcome</p>
                        {formik.values.userid &&
                          formik.values.password &&
                          inCorrectCredentials && (
                            <div className={incorrectCredential}>
                              Incorrect User Credentials
                            </div>
                          )}
                      </div>

                      <div>
                        <p className={inputTitle}>Your Email</p>
                        <div className={outlineInputField}>
                          <OutlinedInput
                            className={inputField}
                            fullWidth
                            placeholder="Username@domainname.com"
                            type="text"
                            name="userid"
                            value={formik.values.userid}
                            onChange={formik.handleChange}
                            autoComplete="off"
                            inputProps={{
                              autocomplete: "new-password",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                          />
                          {formik.errors.userid && formik.touched.userid && (
                            <p className={formikErrorClass}>
                              {formik.errors.userid}
                            </p>
                          )}
                          <img className={atttherate} src={AttherateIcon} />
                        </div>
                      </div>
                      <div>
                        <p className={inputTitle}>Password</p>
                        <div className={outlineInputField}>
                          <OutlinedInput
                            className={inputFieldPassword}
                            fullWidth
                            placeholder="&#9913;&#9913;&#9913;&#9913;&#9913;&#9913;&#9913;&#9913;"
                            type="password"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            autoComplete="off"
                            inputProps={{
                              autocomplete: "new-password",
                              form: {
                                autocomplete: "off",
                              },
                            }}
                          />
                          {formik.errors.password &&
                            formik.touched.password && (
                              <p className={formikErrorClass}>
                                {formik.errors.password}
                              </p>
                            )}
                          <img
                            className={eyeOff}
                            src={showPassword ? OpenEyeIcon : EyeOff}
                            onClick={handlePasswordVisibility}
                          />
                        </div>
                      </div>
                      <div className={forgotPasswordSection}>
                        <p className={forgotPassword}>Forgot Password</p>
                      </div>
                      <div className={loginButton}>
                        <Button variant="contained" fullWidth type="submit">
                          {"Login"}
                        </Button>
                      </div>
                    </form>
                  </Box>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
        <p className={copyrights}>© 2023. All Rights Reserved</p>
      </div>
    </>
  );
};

export default AdminPanelLogin;

