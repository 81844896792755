const appData = {
  // googleApiKey: "AIzaSyDykLcSVPpD9Kw0VoZUkrlpMOCKOVWs7Bg",
  googleApiKey: "AIzaSyBjnsedFLB4nVkgtsi5nl3fMcCHxLcDMVI",
  dashBoardLogoName: "SMART LOGISTICS",
  googleMapColor: {
    mapBgColor: "#F2F5F5",
    roadColor: "#F2F5F5",
    textColor: "#F2F5F5",
    riverColor: "#F2F5F5",
  },
  
};
export default appData;
