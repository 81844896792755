import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(
  () => ({
    dashboardRootContainer: () => ({
      height: "100%",
      fontSize: "0.9vw",
    }),
    dashboardGridContainer: () => ({
      height: "100%",
      padding: "1%",
      paddingBottom: "0",
    }),
    gridItemSection: () => ({
      background: "rgba(242, 245, 245, 1)",
      height: "100%",
      padding: "3%",
      borderRadius: "2%",
    }),
    gridSectionLevelName: () => ({
      background: "rgba(7, 38, 36, 0.7)",
      padding: "1.5% 4%",
      display: "inline-flex",
      color: "white",
      borderRadius: "1vh",
      fontWeight: "600",
      letterSpacing: "0.07vh",
      width : "22%",
    }),
    gridSectionMapContainer: () => ({
      height: "92%",
      padding: "1%",
      position: "relative",
    }),
    fullScreenIconSection: () => ({
      right: "0vw",
      bottom: "0vh",
      position: "absolute",
      height: "2.5vw",
      cursor: "pointer",
    }),
    liveButtonSection: () => ({
      display: "inline-flex",
      width : "14%",
      position : "absolute",
      right : 0,
      top : "-1vw"
    }),
  }),
  { index: 1 }
);
export default useStyles;
