/** @format */

import { makeStyles } from "@mui/styles";
import stadiumBanner from "../../assets/login_bg.jpg";
import muiTheme from "../../theme/muiTheme";

const useStyles = makeStyles(
  () => ({
    loginBannerSection: (props: any) => ({
      backgroundImage: `url("${stadiumBanner}")`,
      // background: props?.palette?.login?.loginBg,
      // mixBlendMode: "hard-light",
      // backdropFilter: "blur(42px)",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
    loginFormSection: (props: any) => ({
      backdropFilter: "blur(0px)",
      borderRadius: "0px 0px 5px 5px",
      background: "#FFFFFF", //rgba(51, 51, 51, 0.6)
      // color: props?.palette?.login?.loginBannerTitle,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "32.1vw !important",
      padding: "4.7vw 4vw 4vw 3.5vw",
      margin: "0 auto",
    }),
    innerForm: (props: any) => ({}),
    welcomeSection: (props: any) => ({}),
    incorrectCredential: () => ({
      position: "absolute",
      bottom: "-2vw",
      color: "red",
      fontSize: "0.9vw",
      fontFamily: `'Noto Sans', sans-serif`,
    }),
    welcomeContent: (props: any) => ({
      fontFamily: `'Noto Sans', sans-serif`,
      fontSize: "1.7000000000000002vw",
      fontWeight: 500,
      color: "#3A345E",
      textAlign: "left",
      marginBottom: "5%",
    }),

    forgotPasswordSection: (props: any) => ({
      position: "relative",
      textAlign: "right",
      marginBottom: "5%",
    }),

    forgotPassword: (props: any) => ({
      fontFamily: `'Noto Sans', sans-serif`,
      fontWeight: "400",
      fontSize: "0.8vw",
      color: "rgba(87, 77, 77, 1)",
      cursor: "pointer",
    }),

    atttherate: (props: any) => ({
      width: "1vw",
      position: "absolute",
      right: "2%",
      top: "33%",
      transform: " translate(-50%, -50%)",
    }),

    inputTitle: (props: any) => ({
      fontFamily: `'Noto Sans', sans-serif`,
      fontWeight: " 500",
      fontSize: "0.8999999999999999vw",
      lineHeight: "36px",
      color: "#4A5568",
      marginBottom: "1%",
    }),
    eyeOff: (props: any) => ({
      width: "1vw",
      position: "absolute",
      right: "2%",
      top: "40%",
      transform: " translate(-50%, -50%)",
      cursor: "pointer",
    }),
    inputField: (props: any) => ({
      marginBottom: "6%",
      "& .MuiInputBase-input": {
        fontFamily: `'Noto Sans', sans-serif`,
        fontSize: "0.8vw",
        lineHeight: "48px",
        color: "#2D3748",
        // border: "1px solid #93A8C1 !important",
        background: "#FFFFFF !important",
        WebkitBoxShadow: "#FFFFFF !important",
        padding: "4%",
        "&::placeholder": {
          color: "rgba(87, 77, 77, 1)",
          opacity: 0.5,
        },
      },
    }),
    inputFieldPassword: (props: any) => ({
      marginBottom: "4%",
      "& .MuiInputBase-input": {
        fontFamily: `'Noto Sans', sans-serif`,
        fontSize: "0.8vw",
        lineHeight: "48px",
        color: "#2D3748",
        padding: "4%",
        "&::placeholder": {
          color: "rgba(87, 77, 77, 1)",
          opacity: 0.5,
        },
      },
    }),
    loginButton: (props: any) => ({
      marginTop: 18,
      "& .MuiButtonBase-root": {
        background: "rgba(7, 38, 36, 1)",
        borderRadius: "5px",
        fontFamily: `'Noto Sans', sans-serif`,
        fontSize: "1vw",
        color: "rgba(255, 255, 255, 1) !important",
        padding: "3%",
        textTransform: "capitalize",
        "&:hover": {
          background: "rgba(7, 38, 36, 1)",
        },
        "&:-webkit-autofill::first-line": {
          fontSize: "1vw",
        },
      },
    }),
    formikErrorClass: (props: any) => ({
      fontFamily: `'Noto Sans', sans-serif`,
      color: "red",
      padding: 0,
      margin: 0,
      paddingTop: 5,
      fontSize: "0.7vw",
      position: "absolute",
      fontWeight: " 500",
      bottom: "0.2vw",
      left: 0,
    }),
    outlineInputField: (props: any) => ({
      position: "relative",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(87, 77, 77, 1)",
      },
      "& .MuiOutlinedInput-root": {
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(87, 77, 77, 1)",
          },
        },
      },
    }),
    llaLogoSection: (props: any) => ({
      boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.05)",
      background: "rgba(7, 38, 36, 1)",
      padding: "5% 4.5% 2.7% 4.5%",
      "& img": {
        width: "8vw",
      },
    }),
    loginPage: () => ({
      position: "relative",
    }),
    copyrights: () => ({
      position: "fixed",
      bottom: "1.8499999999999999vw",
      color: "white",
      width: "100%",
      textAlign: "center",
      fontSize: "0.8vw",
      fontFamily: `'Noto Sans', sans-serif`,
      fontWeight: 700,
    }),
    formSection: (props: any) => ({
      textAlign: "center",
      letterSpacing: "4px",
    }),
    adminPanel: (props: any) => ({
      display: "flex",
      justifyContent: "center",
      padding: "30px 0 0 0",
      cursor: "pointer",
      color: "#333333",
      fontSize: "1vw",
      fontFamily: `'Noto Sans', sans-serif`,
      fontWeight: 500,
    }),
  }),
  { index: 1 }
);
export default useStyles;
