import { useState, useEffect, useRef, useMemo } from "react";
import ArrowRightIcon from "../../assets/arrowRightIcon.svg";
import ArrowDownIcon from "../../assets/arrowDownIcon.svg";
import SearchIcon from "../../assets/Union.svg";
import ProgressBarAccordianListItem from "components/ProgressBarAccordianListItem";
import SsOptLoader from "../../assets/SS-Opt-loader.gif";
import { AutoSizer, List } from 'react-virtualized';
import {
  ContentContainer,
  RootContainer,
  TitleContainer,
  TabContainer,
  TabItem,
  ContentItemContainer,
  ContentItemTitle,
  ArrowContainer,
  RootListContainer,
  TabListContainer,
  TabListContainerSection,
  SearchContainer,
  SearchInput,
  ResetButton,
  SearchCloseButton,
  SearchButton,
  NoResultText,
} from "./styles";

interface Elements {
  title: string;
  min: number;
  max: number;
  step: number;
  value: number;
}

// interface TabList {
//   name: string;
//   items: Elements[];
// }

// interface MainProps {
//   id: string;
//   title: string;
//   tabList: TabList[];
//   expandedTab: string | any;
// }

const FullScreenListItem = (props: any) => {
  const { data, selectedRightObject } = props;
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string | undefined>("");
  const [currentIndex, setCurrentIndex] = useState<any>();
  // const [searchResults, setSearchResults] = useState<any[]>(
  //   data &&
  //     Object.keys(data).length !== 0 &&
  //     data?.[
  //       tabIndex === 0
  //         ? "category1"
  //         : tabIndex === 1
  //         ? "category2"
  //         : "category3"
  //     ].listData
  // );

  const listData = useMemo(() => {
    if (data && Object.keys(data).length !== 0) {
      return data?.[
        tabIndex === 0
          ? "category1"
          : tabIndex === 1
          ? "category2"
          : "category3"
      ].listData;
    }
    return [];
  }, [data, tabIndex]);

  const searchTextRef = useRef("");

  useEffect(() => {
    // data &&
    //   Object.keys(data).length !== 0 &&
    //   setSearchResults(
    //     data?.[
    //       tabIndex === 0
    //         ? "category1"
    //         : tabIndex === 1
    //         ? "category2"
    //         : "category3"
    //     ].listData
    //   );
    data &&
      Object.keys(data).length !== 0 &&
      onSearch(
        data?.[
          tabIndex === 0
            ? "category1"
            : tabIndex === 1
            ? "category2"
            : "category3"
        ].listData,
        searchTextRef.current
      );
  }, [data]);

  const searchResults = useMemo(() => {
    if (searchString) {
      return listData.filter((item: any) =>
        item.title.toLowerCase().includes(searchString?.toLowerCase())
      );
    }
    return listData;
  }, [listData, searchString]);

  // let tabsList: any = [];
  // if (data?.id === 6) {
  //   tabsList = [{ label: "RESTROOMS" }];
  // } else {
  //   tabsList = [
  //     { label: "BEVERAGES" },
  //     { label: "CONCESSIONS" },
  //     { label: "RESTROOMS" },
  //   ];
  // }

  const tabsList = useMemo(() => {
    if (data?.id === 6) {
      return [{ label: "RESTROOMS" }];
    } else {
      return [
        { label: "BEVERAGES" },
        { label: "CONCESSIONS" },
        { label: "RESTROOMS" },
      ];
    }
  }, [data?.id]);

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  const isInitialRender = useRef(1);

  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(
    isInitialRender.current === 1 ? true : false
  );

  const onSearch = (list: any, value: any) => {
    searchTextRef.current = value;
    setSearchString(value);
    // const search = list?.filter((item: any) =>
    //   item.title.toLowerCase().includes(searchString?.toLowerCase())
    // );
    // setSearchResults(search);
  };

  useEffect(() => {
    setSearchString("");
    setShowSearch(false);
    setTabIndex(0);
    searchTextRef.current = "";
    if (isInitialRender.current <= 2) {
      // Skip the logic for the initial render
      isInitialRender.current += isInitialRender.current;
    } else {
      setIsDataLoaded(false);
      setTimeout(() => {
        setIsDataLoaded(true);
      }, 500);
    }
  }, [selectedRightObject]);

  const rowRenderer = ({ index, key, style }: any) => {
    const subList = searchResults[index];
    return (
      <div key={key} style={style}>
        <ProgressBarAccordianListItem
          progressBarTitle={subList?.title}
          progressBarValue={subList?.count}
        />
      </div>
    );
  };
  

  const progressBarAccordianListItemUseMemo = useMemo(
    () =>
      searchResults?.length > 0 ? (
        searchResults?.every((subList: any) => subList?.count) ? (
          // searchResults?.map((subList: any) => {
          //   return (
          //     <ProgressBarAccordianListItem
          //       progressBarTitle={subList?.title}
          //       progressBarValue={subList?.count}
          //     />
          //   );
          // })
          
              <AutoSizer>
               {({ width, height }:{ width:number, height:number }) => (
              <List
                width={width - 5} // Set the width of the list container
                height={height} // Set the height of the list container
                rowCount={searchResults?.length}
                rowHeight={100} // Set the height of each item
                rowRenderer={rowRenderer}
              />
              )}
              </AutoSizer>
          
        ) : (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={SsOptLoader} width={"10%"} />
            </div>
          </>
        )
      ) : (
        <NoResultText>No Result Available</NoResultText>
      ),
    [searchResults, rowRenderer]
  );

  return (
    <>
      {useMemo(() => {
        if (!isDataLoaded) {
          return (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={SsOptLoader} width={"20%"} />
            </div>
          );
        } else {
          return (
            <RootContainer
              key={data?.id}
              style={{
                background: "#092E2B",
              }}
            >
              <RootListContainer>
                <TitleContainer>{data?.level}</TitleContainer>
              </RootListContainer>
              <ContentContainer>
                <TabListContainer>
                  {showSearch ? (
                    <SearchContainer>
                      <SearchInput
                        type="text"
                        placeholder="Search"
                        value={searchString}
                        onChange={(evt: any) =>
                          onSearch(
                            data?.[
                              tabIndex === 0
                                ? "category1"
                                : tabIndex === 1
                                ? "category2"
                                : "category3"
                            ].listData,
                            evt?.target?.value
                          )
                        }
                      />
                      <ResetButton
                        onClick={() => {
                          setSearchString("");
                          searchTextRef.current = "";
                        }}
                      >
                        X
                      </ResetButton>
                    </SearchContainer>
                  ) : (
                    <TabContainer>
                      {tabsList?.map((listItem: any, tabIndexItem: number) => {
                        return (
                          <TabItem
                            key={tabIndexItem}
                            selected={tabIndexItem === tabIndex}
                            onClick={() => handleTabChange(tabIndexItem)}
                          >
                            {listItem.label}
                          </TabItem>
                        );
                      })}
                    </TabContainer>
                  )}

                  {showSearch ? (
                    <SearchCloseButton
                      onClick={() => {
                        setSearchString("");
                        setShowSearch(false);
                        searchTextRef.current = "";
                      }}
                    >
                      X
                    </SearchCloseButton>
                  ) : (
                    <SearchButton
                      src={SearchIcon}
                      onClick={() => setShowSearch(true)}
                    />
                  )}
                  {/* <img
                src={SearchIcon}
                style={{
                  width: "1vw",
                  cursor: "pointer",
                  padding: "1.5%",
                  borderRadius: "50%",
                  backgroundColor: " #2d5d59",
                }}
              /> */}
                </TabListContainer>
                <TabListContainerSection>
                  {progressBarAccordianListItemUseMemo}
                </TabListContainerSection>
              </ContentContainer>
            </RootContainer>
          );
        }
      }, [isDataLoaded,
        data,
        showSearch,
        searchString,
        tabIndex,
        tabsList,
      ])}
    </>
  );
};

export default FullScreenListItem;
