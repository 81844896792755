import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles(
  () => ({
    headerSection: () => ({
      height: "4.4vw",
      background: "rgba(7, 38, 36, 1)",
      padding: " 0 0.8vw 0 1.4vw",
    }),
    headerLogoImg: () => ({
      width: "6.6vw",
      marginTop: "0.4vw",
    }),
    headerLeftSection: () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
    }),
    headerRightSection: () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "right",
    }),
    avatharSection: (props: any) => ({
      display: "flex",
      alignItems: "center",
      marginRight: 2,
      color: `white !important`,
      "& .MuiIconButton-root": {
        zIndex: 1500,
        color: `white !important`,
      },
    }),
    avatharText: (props: any) => ({
      fontFamily: `'Noto Sans', sans-serif`,
      fontWeight: "800",
      fontSize: "0.8vw !important",
      color: "rgba(255, 255, 255, 1)",
    }),
    avatharBackground: (props: any) => ({
      backgroundColor: `rgba(45, 94, 89, 1) !important`,
      width: "1.7999999999999998vw !important",
      height: "1.7999999999999998vw !important",
      borderRadius: "10px !important",
    }),
    logoSection: (props: any) => ({
      display: "flex",
      alignItems: "center",
    }),

    avatharName: (props: any) => ({
      marginLeft: "0.9vw",
      marginRight: "0.9vw",
      "& p": {
        margin: 0,
      },
      "& :first-child": {
        fontFamily: `'Noto Sans', sans-serif`,
        fontWeight: 800,
        fontSize: "0.7000000000000001vw",
        color: "white",
        textTransform: "capitalize",
        marginBottom: "2%",
      },
      "& :last-child": {
        fontFamily: `'Noto Sans', sans-serif`,
        fontSize: "0.5vw",
        fontWeight: 600,
        color: "white",
      },
    }),
    avatharIcon: (props: any) => ({
      width: "1.2vw",
      cursor: "pointer",
      "&.MuiIconButton-root": {
        color: "white",
      },
    }),
    logoutSection: (props: any) => ({
      display: "flex",
      alignItems: "center",
    }),
    logoutImg: (props: any) => ({
      marginRight: "0.5vw",
      width: "1vw",
    }),
    personIconClass: (props: any) => ({
      marginRight: "4px",
    }),
    logoutText: (props: any) => ({
      fontSize: "0.7000000000000001vw !important",
      fontWeight: "700 !important",
      fontFamily: `'Noto Sans', sans-serif !important`,
      color: "rgba(7, 38, 36, 1)",
    }),
    headerCustomMenu: (props: any) => ({
      zIndex: " 1501 !important",
      left: "11px !important",
      marginTop: "2.3vw !important",
      "& .MuiMenu-list": {
        background: `white !important`,
        marginTop: "1vw !important",
        border: `1px solid #d7d7dd7 !important`,
        borderRadius: "4px !important",
      },
      "& .MuiPaper-root": {
        background: "none !important",
        boxShadow: "none !important",
        minWidth: "7vw",
      },
      "& .MuiList-root": {
        "&::before": {
          width: "0.8vw",
          height: "0.8vw",
          content: '""',
          transform: "rotate(225deg)",
          boxSizing: "border-box",
          backgroundColor: "white",
          position: "absolute",
          right: 17,
          top: -5,
          border: `1px solid #d7d7d7`,
          borderStyle: "solid",
          borderWidth: "0px 1px 1px 0px",
        },
      },
      "& .MuiButtonBase-root": {
        padding: "6px 14px !important",
        "&:hover": {
          background: "transparent !important",
        },
      },
    }),
  }),
  { index: 1 }
);
export default useStyles;
