import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import headerLogo from "../../assets/login_logo.svg";
import Avatar from "@mui/material/Avatar";
import profileDropdown from "../../assets/profile-dropdown-arrow.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import theme from "../../theme/theme";
import Logout from "../../assets/logoutIcon.svg";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import { setUserLogin } from "../../redux/actions/loginActions";
import useStyles from "./styles";

const Header: React.FC = () => {
  const [appTheme, setAppTheme] = useState(theme?.defaultTheme);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const user = useSelector((state: any) => state.login.loginData);

  const {
    headerSection,
    headerLogoImg,
    headerLeftSection,
    avatharName,
    avatharSection,
    avatharIcon,
    avatharBackground,
    logoutSection,
    headerCustomMenu,
    logoutImg,
    personIconClass,
    logoutText,
    headerRightSection,
    avatharText,
  } = useStyles(appTheme);

  const [name, setName] = useState({
    firstName: "Antonio ",
    lastName: "Ross",
    initials: "AR",
  });

  const [anchorElUser, setAnchorElUser] = useState(null);

  const menuOptions = ["Logout"];
  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = (menuOption: string) => {
    if (menuOption === "Logout") {
      localStorage.removeItem("user");
      localStorage.clear();
      dispatch(setUserLogin({}));
      navigate("/login");
    }
    setAnchorElUser(null);
  };

  useEffect(() => {
    if (user) {
      let userFirstName = "",
        userLastName = "",
        initial1 = "",
        initial2 = "";
      let fname = user?.firstName;
      let lname = user?.lastName;
      if (fname?.split("")?.length > 0) {
        userFirstName = fname?.split("")[0].toUpperCase() + fname.substring(1);
      }
      if (lname?.split("")?.length > 0) {
        userLastName = lname?.split("")[0].toUpperCase() + lname.substring(1);
      }
      if (userFirstName && userFirstName?.split("").length > 0) {
        initial1 = userFirstName?.split("")[0].toUpperCase();
      }
      if (userLastName && userLastName?.split("").length > 0) {
        initial2 = userLastName?.split("")[0].toUpperCase();
      }
      setName({
        firstName: userFirstName,
        lastName: userLastName,
        initials: initial1 + initial2,
      });
    }
  }, [user]);

  return (
    <Grid container xs={12} className={headerSection}>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        className={headerLeftSection}>
        <img src={headerLogo} className={headerLogoImg} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        className={headerRightSection}>
        <div className={avatharSection}>
          <Avatar className={avatharBackground}>
            <p className={avatharText}>{name.initials}</p>
          </Avatar>
          <div className={avatharName}>
            <p>{name?.firstName + " " + name?.lastName}</p>
            <p>Site Supervisor</p>
          </div>
          <img
            src={profileDropdown}
            onClick={handleOpenUserMenu}
            className={avatharIcon}
          />
          <Menu
            className={headerCustomMenu}
            sx={{ mt: "25px" }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorElUser}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}>
            {menuOptions &&
              menuOptions.length > 0 &&
              menuOptions.map((menuOptions) => (
                <MenuItem
                  key={menuOptions}
                  onClick={() => {
                    handleCloseUserMenu(menuOptions);
                  }}>
                  <div className={logoutSection}>
                    {menuOptions && menuOptions === "Logout" ? (
                      <img className={logoutImg} src={Logout} alt="logout" />
                    ) : (
                      <PersonIcon className={personIconClass} />
                    )}

                    <Typography className={logoutText} textAlign="center">
                      {menuOptions}
                    </Typography>
                  </div>
                </MenuItem>
              ))}
          </Menu>
        </div>
      </Grid>
    </Grid>
  );
};

export default Header;
