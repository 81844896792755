import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import useStyles from "./styles";

const Footer = (props:any) => {
  const { dashboardCopyrights, copyrightsSection } = useStyles();
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={copyrightsSection}>
        <p className={dashboardCopyrights}>© 2023. All Rights Reserved</p>
      </Grid>
    </>
  );
};

export default Footer;
