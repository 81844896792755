export const formattedDataLevel = (originalData: any) => {

  const convertedData = Object.entries(originalData).map(([level, items] : any) => {
    return{
    id: items[0]?.id,
    title: `level${level.slice(-1)}`,
    event: "NBA-04-10-2023",
    level: `Level ${level.slice(-1)} - ${level === "level1" ? "Event" : level === "level2" ? "Charter" : level === "level3" ? "Plaza" : level === "level4" ? "Terrace" : level === "level5" ? "Balcony" : "Mechanical"}`,
    start: "20-07-2023 | 3:00 PM",
    end: "20-07-2023 | 5:00 PM",
    category1: {
      id: "",
      title: "beverages",
      listData: items?.filter((item:any) => item?.categoryTitle === "beverages").map((item:any) => ({
        id: item?.id,
        title: item?.title,
        count: item?.count,
        radius: item?.radius,
        observation: item?.observation,
        opacity: item?.opacity,
      })),
    },
    category2: {
      id: "",
      title: "concessions",
      listData: items?.filter((item:any) => item?.categoryTitle === "concessions").map((item:any) => ({
        id: item?.id,
        title: item?.title,
        count: item?.count,
        radius: item?.radius,
        observation: item?.observation,
        opacity: item?.opacity,
      })),
    },
    category3: {
      id: "",
      title: "restRoom",
      listData: items?.filter((item:any) => item?.categoryTitle === "restRoom").map((item:any) => ({
        id: item?.id,
        title: item?.title,
        count: item?.count,
        radius: item?.radius,
        observation: item?.observation,
        opacity: item?.opacity,
      })),
    },
  }});
  
  
  return convertedData;
};

export const formattedCombinedData = (data: any) => {
  let dataListArray: any;
  let dataSet1: any = [];
  let dataSet2: any = [];
  let dataSet3: any = [];
  let dataSet4: any = [];
  let dataSet5: any = [];
  let dataSet6: any = [];

  data?.map((value: any) => {
    if (value?.title === "level1") {
      value?.category1?.listData?.map((val: any) => {
        dataSet1?.push({
          ...val,
          categoryTitle: value?.category1?.title,
          listTitle: val?.title,
          levelName: value?.title,
          id: value?.id,
          categoryId : val?.id
        });
      });

      value?.category2?.listData?.map((val: any) => {
        dataSet1?.push({
          ...val,
          categoryTitle: value?.category2?.title,
          listTitle: val?.title,
          levelName: value?.title,
          id: value?.id,
          categoryId : val?.id
        });
      });

      value?.category3?.listData?.map((val: any) => {
        dataSet1?.push({
          ...val,
          categoryTitle: value?.category3?.title,
          listTitle: val?.title,
          levelName: value?.title,
          id: value?.id,
          categoryId : val?.id
        });
      });
          }

    if (value?.title === "level2") {
      value?.category1?.listData?.map((val: any) => {
        dataSet2?.push({
          ...val,
          categoryTitle: value?.category1?.title,
          listTitle: val?.title,
          levelName: value?.title,
          id: value?.id,
          categoryId : val?.id
        });
      });

      value?.category2?.listData?.map((val: any) => {
        dataSet2?.push({
          ...val,
          categoryTitle: value?.category2?.title,
          listTitle: val?.title,
          levelName: value?.title,
          id: value?.id,
          categoryId : val?.id
        });
      });

      value?.category3?.listData?.map((val: any) => {
        dataSet2?.push({
          ...val,
          categoryTitle: value?.category3?.title,
          listTitle: val?.title,
          levelName: value?.title,
          id: value?.id,
          categoryId : val?.id
        });
      });
          }

    if (value?.title === "level3") {
      value?.category1?.listData?.map((val: any) => {
        dataSet3?.push({
          ...val,
          categoryTitle: value?.category1?.title,
          listTitle: val?.title,
          levelName: value?.title,
          id: value?.id,
          categoryId : val?.id
        });
      });

      value?.category2?.listData?.map((val: any) => {
        dataSet3?.push({
          ...val,
          categoryTitle: value?.category2?.title,
          listTitle: val?.title,
          levelName: value?.title,
          id: value?.id,
          categoryId : val?.id
        });
      });

      value?.category3?.listData?.map((val: any) => {
        dataSet3?.push({
          ...val,
          categoryTitle: value?.category3?.title,
          listTitle: val?.title,
          levelName: value?.title,
          id: value?.id,
          categoryId : val?.id
        });
      });
          }

    if (value?.title === "level4") {
      value?.category1?.listData?.map((val: any) => {
        dataSet4?.push({
          ...val,
          categoryTitle: value?.category1?.title,
          listTitle: val?.title,
          levelName: value?.title,
          id: value?.id,
          categoryId : val?.id
        });
      });

      value?.category2?.listData?.map((val: any) => {
        dataSet4?.push({
          ...val,
          categoryTitle: value?.category2?.title,
          listTitle: val?.title,
          levelName: value?.title,
          id: value?.id,
          categoryId : val?.id
        });
      });

      value?.category3?.listData?.map((val: any) => {
        dataSet4?.push({
          ...val,
          categoryTitle: value?.category3?.title,
          listTitle: val?.title,
          levelName: value?.title,
          id: value?.id,
          categoryId : val?.id
        });
      });
          }

    if (value?.title === "level5") {
      value?.category1?.listData?.map((val: any) => {
        dataSet5?.push({
          ...val,
          categoryTitle: value?.category1?.title,
          listTitle: val?.title,
          levelName: value?.title,
          id: value?.id,
          categoryId : val?.id
        });
      });

      value?.category2?.listData?.map((val: any) => {
        dataSet5?.push({
          ...val,
          categoryTitle: value?.category2?.title,
          listTitle: val?.title,
          levelName: value?.title,
          id: value?.id,
          categoryId : val?.id
        });
      });

      value?.category3?.listData?.map((val: any) => {
        dataSet5?.push({
          ...val,
          categoryTitle: value?.category3?.title,
          listTitle: val?.title,
          levelName: value?.title,
          id: value?.id,
          categoryId : val?.id
        });
      });
          }

    if (value?.title === "level6") {
      value?.category1?.listData?.map((val: any) => {
        dataSet6?.push({
          ...val,
          categoryTitle: value?.category1?.title,
          listTitle: val?.title,
          levelName: value?.title,
          id: value?.id,
          categoryId : val?.id
        });
      });

      value?.category2?.listData?.map((val: any) => {
        dataSet6?.push({
          ...val,
          categoryTitle: value?.category2?.title,
          listTitle: val?.title,
          levelName: value?.title,
          id: value?.id,
          categoryId : val?.id
        });
      });

      value?.category3?.listData?.map((val: any) => {
        dataSet6?.push({
          ...val,
          categoryTitle: value?.category3?.title,
          listTitle: val?.title,
          levelName: value?.title,
          id: value?.id,
          categoryId : val?.id
        });
      });
          }
  });

  dataListArray = {
    level1: dataSet1,
    level2: dataSet2,
    level3: dataSet3,
    level4: dataSet4,
    level5: dataSet5,
    level6: dataSet6,
  };
  return dataListArray;
};

