const eventsList = [{
    "id": "1",
    "event": "NBA1 : ",
    "start":"08:00:00 AM",
    "end":"10:00:00 AM"
},
{
    "id": "2",
    "event": "NBA2 : ",
    "start":"11:00:00 AM",
    "end":"01:00:00 PM"
},
{
    "id": "3",
    "event": "NBA3 : ",
    "start":"02:00:00 PM",
    "end":"04:00:00 PM"
},
{
    "id": "4",
    "event": "NBA4 : ",
    "start":"05:00:00 PM",
    "end":"07:00:00 PM"
},
{
    "id": "5",
    "event": "NBA5 : ",
    "start":"08:00:00 PM",
    "end":"10:00:00 PM"
}
];

export default eventsList;
