const userList = [
  {
  userName: "florence.paul",
  userId: 1,
  loginTime: "2022-11-21T10:49:58.088+00:00",
  roles: ["Admin"],
  phoneNum: "+15852826892",
  email: "Sensyonadmin@gmail.com",
  currentRoleType: "ADMIN",
  firstName: "Florence",
  lastName: "Paul",
  password: "FP@2023#",
},
{
  userName: "john.smith",
  userId: 2,
  loginTime: "2022-11-21T10:49:58.088+00:00",
  roles: ["Admin"],
  phoneNum: "+15852826892",
  email: "Sensyonadmin@gmail.com",
  currentRoleType: "ADMIN",
  firstName: "John",
  lastName: "Smith",
  password: "JS@2023#",
},
];

export default userList;