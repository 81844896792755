import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(
  () => ({
    dashboardRootContainerParent: (props: any) => ({
      height: props?.fullScreenActive
        ? "calc(100vh - 4.4vw)"
        : "calc(100vh - 6.4vw)",
    }),
  }),
  { index: 1 }
);
export default useStyles;
