import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ProtectedRoutes from "./protectedRoutes";
import Login from "../pages/Login";
import Dashboard from "pages/Dashboard";
const VIOT_Routes = () => {
  // const user = useSelector((state: any) => state.login.loginData);

  return (
    <>
      <Routes>
        {/** Protected Routes */}
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="/" element={<Navigate replace to="login" />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>

        {/** Public Routes */}
        <Route path="/login" element={<Login />} />
        {/** Permission denied route */}
        <Route path="/denied" element={<div>No permission</div>} />
      </Routes>
    </>
  );
};

export default VIOT_Routes;
