//@ts-nocheck
import { useState, useEffect, useRef, useMemo } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import useStyles from "./styles";
import Select from "elements/Select";
import moment from "moment";

interface DateTimeFormatFunctions {
  date: string;
  time: string;
  amPm: string;
}

interface Time {
  hours: number;
  minutes: number;
  isAM: boolean;
}

const PlayerContainer = (props: any) => {
  const {
    isPlaying,
    setIsPlaying,
    manuallySlidedValue,
    setManuallySlidedValue,
    setIsManuallyClicked,
    getCurrentPlayerTime,
    prevCurrentTime,
    setPrevCurrentTime,
    playbackSpeed,
    setPlaybackSpeed,
    currentTime,
    setCurrentTime,
    fullScreenActive,
    selectedRightObject,
    manuallySlidedValueOnLiveEvent,
    setManuallySlidedValueOnLiveEvent,
    playLiveEvent,
    setPlayLiveEvent,
    playerControlIsDisable,
    setPlayerControlIsDisable,
  } = props;
  const [showRangeValue, setShowRangeValue] = useState<boolean>(false);
  const [sliderMaximumValue, setSliderMaximumValue] = useState<number>(7200);

  const [pastEventPlayBackStartEndTime, setPastEventPlayBackStartEndTime] =
    useState<any>({ startTime: "02:00:00 PM", endTime: "04:00:00 PM" });

  useEffect(() => {
    getCurrentPlayerTime(currentTime);
  }, [manuallySlidedValue]);

  
  const getTimeDifferenceInSecondsSliderLength = (
    start: string,
    end: string
  ): number => {
    const parseTime = (timeString: string): Time => {
      const [time, ampm] = timeString.split(" ");
      const [hours, minutes] = time.split(":").map(Number);
      return { hours, minutes, isAM: ampm === "AM" };
    };

    const convertTo24HourFormat = (time: Time): Time => {
      if (time.hours === 12) {
        time.hours = time.isAM ? 0 : 12;
      } else if (!time.isAM) {
        time.hours += 12;
      }
      return time;
    };

    const calculateTimeDifferenceInSeconds = (
      startTime: Time,
      endTime: Time
    ): number => {
      const startTimestamp = startTime.hours * 3600 + startTime.minutes * 60;
      const endTimestamp = endTime.hours * 3600 + endTime.minutes * 60;
      return endTimestamp - startTimestamp;
    };

    const startTime = parseTime(start);
    const endTime = parseTime(end);

    const start24Hour = convertTo24HourFormat(startTime);
    const end24Hour = convertTo24HourFormat(endTime);

    let timeDifference = calculateTimeDifferenceInSeconds(
      start24Hour,
      end24Hour
    );

    if (timeDifference < 0) {
      // If the end time is on the next day, add 24 hours to the difference
      timeDifference += 24 * 3600;
    }

    return timeDifference;
  };

  const generateTimeArray = (
    startTime: string,
    endTime: string,
    withSeconds: boolean
  ): string[] => {
    // Helper functions
    const convertTo24HourFormat = (time: string): string => {
      const [timeStr, period] = time.split(" ");
      const [hours, minutes, seconds] = timeStr.split(":").map(Number);

      let hours24 = hours;
      if (period === "PM" && hours !== 12) {
        hours24 = hours + 12;
      } else if (period === "AM" && hours === 12) {
        hours24 = 0;
      }

      return `${String(hours24).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}:${String(seconds).padStart(2, "0")}`;
    };

    const convertTo12HourFormat = (time24: string): string => {
      const [hours, minutes, seconds] = time24.split(":").map(Number);

      let period = "AM";
      let hours12 = hours;
      if (hours >= 12) {
        period = "PM";
        hours12 = hours === 12 ? 12 : hours - 12;
      }
      if (withSeconds) {
        return `${String(hours12).padStart(2, "0")}:${String(minutes).padStart(
          2,
          "0"
        )}:${String(seconds).padStart(2, "0")} ${period}`;
      }
      return `${String(hours12).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )} ${period}`;
    };

    const calculateTimeInterval = (
      startTime: string,
      endTime: string
    ): number => {
      const startTimeDate = new Date(`2000-01-01T${startTime}`);
      const endTimeDate = new Date(`2000-01-01T${endTime}`);
      return (endTimeDate.getTime() - startTimeDate.getTime()) / 4;
    };

    const addTimeInterval = (time: string, interval: number): string => {
      const timeDate = new Date(`2000-01-01T${time}`);
      const newTimeDate = new Date(timeDate.getTime() + interval);
      return newTimeDate.toTimeString().split(" ")[0];
    };

    const formatTimeForInitialAndLastTimeString = (
      timeString: string
    ): string => {
      // Split the time string into hours, minutes, and AM/PM
      const [time, ampm] = timeString.split(" ");
      const [hours, minutes] = time.split(":").map(Number);

      // Convert to 12-hour format
      const formattedHours = hours % 12 || 12;

      // Add leading zeros to minutes if needed
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      // Combine the parts to create the formatted time string
      return `${formattedHours}:${formattedMinutes} ${ampm}`;
    };

    const formattedStartTime = formatTimeForInitialAndLastTimeString(startTime);
    const formattedEndTime = formatTimeForInitialAndLastTimeString(endTime);

    const timeArray: string[] = [withSeconds ? startTime : formattedStartTime];

    // Convert start and end times to 24-hour format for better consistency
    const startTime24 = convertTo24HourFormat(startTime);
    const endTime24 = convertTo24HourFormat(endTime);

    // Calculate the interval between each time slot
    const interval = calculateTimeInterval(startTime24, endTime24);

    for (let i = 1; i < 4; i++) {
      const newTime = addTimeInterval(startTime24, interval * i);
      timeArray.push(convertTo12HourFormat(newTime));
    }

    timeArray.push(withSeconds ? endTime : formattedEndTime);
    return timeArray;
  };

  const dateTimeFormatter = (
    isLastOneHour?: boolean,
    isNextOneHour?: boolean
  ) => {
    const now = new Date();
    const oneHourAgo = isLastOneHour
      ? new Date(now.getTime() - 60 * 60 * 1000)
      : isNextOneHour
      ? new Date(now.getTime() + 60 * 60 * 1000)
      : new Date(now.getTime()); // Subtracting milliseconds for one hour

    const formatDate = oneHourAgo.toLocaleDateString().replace(/\//g, "-");

    const formatTime = oneHourAgo.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });

    const formatAMPM = oneHourAgo
      .toLocaleTimeString([], {
        hour12: true,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
      .slice(-2);
    if (isNextOneHour) {
      return formatTime;
    }
    return { date: formatDate, time: formatTime, amPm: formatAMPM };
  };

  const [oneHourAgoLive, setOneHourAgoLive] = useState<DateTimeFormatFunctions>(
    dateTimeFormatter(true, false)
  );

  const [dateofBothLivePastEvents, setDateofBothLivePastEvents] =
    useState<string>(playLiveEvent ? oneHourAgoLive?.date : "7-26-2023");

  // useEffect(() => {
  //   setPlayLiveEvent(selectedRightObject?.eventStatus);
  //   setPlaybackSpeed(1)
  // }, [selectedRightObject]);

  useEffect(() => {
    setPastEventPlayBackStartEndTime({
      startTime: selectedRightObject?.start,
      endTime: selectedRightObject?.end, //"NBA2 : 07-27-2023"
    });
    const [NBA, date] = selectedRightObject?.label.split(":").map(String);
    setDateofBothLivePastEvents(playLiveEvent ? oneHourAgoLive?.date : date);
  }, [selectedRightObject, playLiveEvent]);

  // Usage
  // const [splittedTimeRangeWithSeconds, setSplittedTimeRangeWithSeconds] =
  //   useState<any>([
  //     "05:00:00 PM",
  //     "05:30:00 PM",
  //     "06:00:00 PM",
  //     "06:30:00 PM",
  //     "07:00:00 PM",
  //   ]);

  const [splittedTimeRangeWithSeconds, setSplittedTimeRangeWithSeconds] =
    useState<any>(
      generateTimeArray(
        pastEventPlayBackStartEndTime?.startTime,
        pastEventPlayBackStartEndTime?.endTime,
        true
      )
    );
  // const [splittedTimeRange, setSplittedTimeRange] = useState<any>([
  //   "05:00 PM",
  //   "05:30 PM",
  //   "06:00 PM",
  //   { lastBeforeTime: "06:30 PM", lastTime: "07:00 PM" },
  // ]);

  const [splittedTimeRange, setSplittedTimeRange] = useState<any>(() => {
    const next2HoursDivided = generateTimeArray(
      pastEventPlayBackStartEndTime?.startTime,
      pastEventPlayBackStartEndTime?.endTime,
      false
    );
    return (
      next2HoursDivided?.length > 4 && [
        next2HoursDivided[0],
        next2HoursDivided[1],
        next2HoursDivided[2],
        {
          lastBeforeTime: next2HoursDivided[3],
          lastTime: next2HoursDivided[4],
        },
      ]
    );
  });

  useEffect(() => {
    setSplittedTimeRangeWithSeconds(
      generateTimeArray(
        pastEventPlayBackStartEndTime?.startTime,
        pastEventPlayBackStartEndTime?.endTime,
        true
      )
    );
    setSplittedTimeRange(() => {
      const next2HoursDivided = generateTimeArray(
        pastEventPlayBackStartEndTime?.startTime,
        pastEventPlayBackStartEndTime?.endTime,
        false
      );
      return (
        next2HoursDivided?.length > 4 && [
          next2HoursDivided[0],
          next2HoursDivided[1],
          next2HoursDivided[2],
          {
            lastBeforeTime: next2HoursDivided[3],
            lastTime: next2HoursDivided[4],
          },
        ]
      );
    });
  }, [pastEventPlayBackStartEndTime]);

  useEffect(() => {
    if (playLiveEvent) {
      setDateofBothLivePastEvents(
        playLiveEvent ? oneHourAgoLive?.date : "7-20-2023"
      );
      const nextOneHour = dateTimeFormatter(false, true);

      const next2HoursDivided = generateTimeArray(
        oneHourAgoLive?.time,
        nextOneHour,
        false
      );
      const next2HoursDividedWithSeconds = generateTimeArray(
        oneHourAgoLive?.time,
        nextOneHour,
        true
      );
      setSplittedTimeRangeWithSeconds(next2HoursDividedWithSeconds);
      setSplittedTimeRange(
        next2HoursDivided?.length > 4 && [
          next2HoursDivided[0],
          next2HoursDivided[1],
          next2HoursDivided[2],
          {
            lastBeforeTime: next2HoursDivided[3],
            lastTime: next2HoursDivided[4],
          },
        ]
      );
    }
  }, [playLiveEvent, oneHourAgoLive, pastEventPlayBackStartEndTime]);

  useEffect(() => {
    if (playLiveEvent) {
      const nextOneHour = dateTimeFormatter(false, true);
      setSliderMaximumValue(
        getTimeDifferenceInSecondsSliderLength(
          oneHourAgoLive?.time,
          nextOneHour
        )
      );
    } else {
      setSliderMaximumValue(
        getTimeDifferenceInSecondsSliderLength(
          pastEventPlayBackStartEndTime?.startTime,
          pastEventPlayBackStartEndTime?.endTime
        )
      );
    }
  }, [splittedTimeRange]);

  useEffect(() => {
    setOneHourAgoLive(dateTimeFormatter(true, false)); //oneHourAgo.toLocaleString()
  }, []);

  const getTimeInSeconds = (timeStr: string): number => {
    // Convert time string to Date object
    const timeParts = timeStr.split(" ");
    const time = timeParts[0];
    const amPm = timeParts[1];
    const [hours, minutes, seconds] = time.split(":").map(Number);

    let totalSeconds = hours * 3600 + minutes * 60 + seconds;

    if (amPm === "PM" && hours !== 12) {
      totalSeconds += 12 * 3600;
    } else if (amPm === "AM" && hours === 12) {
      totalSeconds -= 12 * 3600;
    }

    return totalSeconds;
  };

  const calculateTimeDifferenceInSeconds = (
    startTime: string,
    endTime: string
  ): number => {
    const startSeconds = getTimeInSeconds(startTime);
    const endSeconds = getTimeInSeconds(endTime);
    const differenceInSeconds = endSeconds - startSeconds;
    return differenceInSeconds;
  };

  const handleMouseManuelSlid = (event: any) => {
    setCurrentTime((prev: any) => {
      setPrevCurrentTime(prev);
      return Number(event.target.value);
    });
    setManuallySlidedValue(Number(event.target.value));
    setIsManuallyClicked(true);
    if (playLiveEvent) {
      setManuallySlidedValueOnLiveEvent(Number(event.target.value));
    }
  };
  const isPrevValueExceeds = useRef(false);
  useEffect(() => {
    isPrevValueExceeds.current = false;
  }, [
    isPlaying,
    playLiveEvent,
    manuallySlidedValue,
    manuallySlidedValueOnLiveEvent,
  ]);

  useEffect(() => {
    setPlaybackSpeed(1);
  }, [playerControlIsDisable]);

  
  useEffect(() => {
    let interval: any;
    
    // let isPrevValueExceeds: boolean = false;
    if (isPlaying) {
      interval = setInterval(() => {
        if (playLiveEvent) {
          const currentTimeLive = dateTimeFormatter(false, false);
          const differenceInSeconds = calculateTimeDifferenceInSeconds(
            oneHourAgoLive?.time,
            currentTimeLive?.time
          );
          const hhmm = secondsToHHMMSS(differenceInSeconds, true)
          const [hh, mm]= hhmm && hhmm?.split(":").map(Number)
          const [lasthhmm, amPm] = splittedTimeRange?.length > 0 && splittedTimeRange[3]?.lastTime?.split(" ").map(String)
          const [lastHh, lastMm]= lasthhmm && lasthhmm?.split(":").map(Number)
          
          if (
            manuallySlidedValueOnLiveEvent &&
            manuallySlidedValueOnLiveEvent < differenceInSeconds
          ) {
            setCurrentTime((prev: any) => {
              if (prev >= differenceInSeconds || isPrevValueExceeds.current) {   
                if(!playerControlIsDisable){           
                setPlayerControlIsDisable(true);
                }
                isPrevValueExceeds.current = true;
                if(hh >= lastHh && mm >= lastMm){
                  setOneHourAgoLive(dateTimeFormatter(true, false))
                }
                return differenceInSeconds;
              }
              if (prev >= sliderMaximumValue - 1) {
                setIsPlaying(false);
              }
              if(playerControlIsDisable){
              setPlayerControlIsDisable(false);
              }
              return prev <= sliderMaximumValue - 1 ? prev + playbackSpeed : 0;
            });
          } else {
            setCurrentTime((prev: any) => {
              // if (prev >= sliderMaximumValue - 1) {
              //   setIsPlaying(false);
              // }
              // return prev <= sliderMaximumValue - 1 ? differenceInSeconds : 0;
              if(!playerControlIsDisable){
              setPlayerControlIsDisable(true);
              }
              
              if(hh >= lastHh && mm >= lastMm){
                setOneHourAgoLive(dateTimeFormatter(true, false))
              }
              return differenceInSeconds;
            });
          }
        } else {
          setCurrentTime((prev: any) => {
            if (prev >= sliderMaximumValue - 1) {
              setIsPlaying(false);
            }
            return prev <= sliderMaximumValue - 1 ? prev + playbackSpeed : 0;
          });
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [
    isPlaying,
    playLiveEvent,
    // manuallySlidedValue,
    playbackSpeed,
    manuallySlidedValueOnLiveEvent,
    selectedRightObject,
    splittedTimeRange,
  ]);

  const {
    inputStyle,
    rangeVale,
    underlay,
    breakpoint,
    breakpointValueStyle,
    breakpointLastValueStyle,
    graphCustomSelectDropDown,
  } = useStyles({
    value: currentTime,
    max: sliderMaximumValue,
    fullScreenActive: fullScreenActive,
    playLiveEvent: playLiveEvent,
    playerControlIsDisable: playerControlIsDisable,
  });
  const handlePlayIcon = () => {
    setIsPlaying(!isPlaying);
  };

  const handleThumbMouseEnter = () => {
    setShowRangeValue(true);
  };

  const handleThumbMouseLeave = () => {
    setShowRangeValue(false);
    setIsManuallyClicked(false);
  };

  const splitTime = (timeString: any) => {
    const match = timeString.match(/^(\d{1,2}):(\d{2}):(\d{2})\s*([ap]m)$/i); // timeString.match(/^(\d{1,2}):(\d{2})\s*([ap]m)$/i);   // //timeString.match(/^(\d+)\s*([ap]m)$/i)
    if (!match) {
      throw new Error('Invalid time format. Expected format: "3 pm" or "3am".');
    }

    const hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);
    const seconds = parseInt(match[3], 10);
    const amPm = match[4].toLowerCase();

    return hours * 3600 + minutes * 60 + seconds;
  };

  const secondsToHHMMSS = (seconds: number, isFromTimerCallback:boolean) => {
    const totalSeconds =
      seconds +
      splitTime(
        splittedTimeRangeWithSeconds.length > 0 &&
          splittedTimeRangeWithSeconds[0]
      );
    const hours24 = Math.floor(totalSeconds / 3600) % 24;
    const hours12 = hours24 % 12 === 0 ? 12 : hours24 % 12;
    // +
    // splitTime(splittedTimeRangeWithSeconds.length > 0 && splittedTimeRangeWithSeconds[0]);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    // +
    // splitTime(splittedTimeRangeWithSeconds.length > 0 && splittedTimeRangeWithSeconds[0], "minutes");
    const remainingSeconds = totalSeconds % 60;
    // +
    // splitTime(splittedTimeRangeWithSeconds.length > 0 && splittedTimeRangeWithSeconds[0], "seconds");

    const paddedHours = hours12.toString().padStart(2, "0");
    const paddedMinutes = minutes.toString().padStart(2, "0");
    const paddedSeconds = remainingSeconds.toString().padStart(2, "0");
     if(isFromTimerCallback){
      return `${paddedHours}:${paddedMinutes}`
    }
    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
  };

  const selectList = [
    { label: "1x", value: "1x" },
    { label: "5x", value: "5x" },
    { label: "10x", value: "10x" },
    { label: "15x", value: "15x" },
  ];

  const handleSelect = (selectedDropDownValue: string) => {
    setPlaybackSpeed(parseInt(selectedDropDownValue.slice(0, -1)));
  };

  const playerTooltipElementUseMemo = useMemo(()=>(
    <div
                className={rangeVale}
                style={{
                  opacity: showRangeValue ? 1 : 0,
                }}
              >{`${dateofBothLivePastEvents}, ${secondsToHHMMSS(
                currentTime
              )}`}</div>
  ),[
    rangeVale,
    showRangeValue,
    dateofBothLivePastEvents,
    currentTime,
     secondsToHHMMSS,
  ])

  const playerElementUseMemo = useMemo(()=>(
    <input
                className={inputStyle}
                step={1}
                type="range"
                min={0}
                max={sliderMaximumValue}
                value={currentTime}
                onChange={(e) => {
                  handleMouseManuelSlid(e);
                }}
                onMouseEnter={handleThumbMouseEnter}
                onMouseLeave={handleThumbMouseLeave}
              />
  ),[
    inputStyle, sliderMaximumValue, currentTime
  ])

  const underlayElementUseMemo = useMemo(()=>(
    <div className={underlay}>
                {splittedTimeRange.length > 0 &&
                  splittedTimeRange?.map((item: any, index: number) =>
                    index <= 2 ? (
                      <div className={breakpoint}>
                        <div className={breakpointValueStyle}>{item}</div>
                      </div>
                    ) : (
                      <div className={breakpoint}>
                        <div className={breakpointValueStyle}>
                          {item?.lastBeforeTime}
                        </div>
                        <div className={breakpointLastValueStyle}>
                          {item?.lastTime}
                        </div>
                      </div>
                    )
                  )}
              </div>
  ),[
    splittedTimeRange
  ])

  const playerVelocitySelectElementUseMemo = useMemo(()=>(
    <Select
            initialValue={
              `${playbackSpeed}x`
            }
            selectList={selectList}
            handleSelect={handleSelect}
            customWidth={"100%"}
            customHeight={"100%"}
            customSelectCustom={graphCustomSelectDropDown}
            drowpDownTextColor={"#FFFFFF"}
            dropDownBgColor={"#0F1818"}
            dropDownSelectedBgColor={"#F95E3C"}
            dropDownSelectedTextColor={"#FFFFFF"}
            disabled={playLiveEvent && playerControlIsDisable}
          />
  ),[
    playbackSpeed,
    selectList,
    handleSelect,
    graphCustomSelectDropDown,
    playLiveEvent,
    playerControlIsDisable,
  ])

  

  return (
    <>
      <div
        style={{
          display: "flex",
          padding: fullScreenActive ? "0.6% 0.8% 0.8% 0.8%" : "1%",
          columnGap: fullScreenActive ? "1% " : "2%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              background: "#000",
              padding: "0.5% 2% 0.8% 1%",
              display: "flex",
              width: fullScreenActive ? "93vw" : "68vw",
              justifyContent: "space-between",
              borderRadius: "7px",
            }}
          >
            <div
              style={{
                width: fullScreenActive ? "2%" : "2.5%",
                display: "flex",
              }}
              onClick={handlePlayIcon}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {isPlaying ? (
                  <PauseIcon
                    // onClick={handleClose}

                    sx={{
                      width: "100%",
                      height: "100%",
                      padding: 0,
                      margin: 0,
                      color: "#ffffff",
                      background: "#4E4E4B",
                      borderRadius: "5px",
                    }}
                  />
                ) : (
                  <PlayArrowIcon
                    // onClick={handleClose}

                    sx={{
                      width: "100%",
                      height: "100%",
                      padding: 0,
                      margin: 0,
                      color: "#ffffff",
                      background: "#4E4E4B",
                      borderRadius: "5px",
                    }}
                  />
                )}
              </div>
            </div>
            <div style={{ width: "94%", position: "relative" }}>
            <div
                className={rangeVale}
                style={{
                  opacity: showRangeValue ? 1 : 0,
                }}
              >{`${dateofBothLivePastEvents}, ${secondsToHHMMSS(
                currentTime
              )}`}</div>
              {playerElementUseMemo}
              {underlayElementUseMemo}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {playerVelocitySelectElementUseMemo}
        </div>
      </div>
    </>
  );
};

export default PlayerContainer;
