import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import DashboardContainer from "components/DashboardContainer";
import useStyles from "./styles";
import Header from "components/Header";
import Footer from "components/Footer";
import SsOptLoader from "../../assets/SS-Opt-loader.gif";

const Dashboard = (props: any) => {
  const [fullScreenActive, setFullScreenActive] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false)

  const { dashboardRootContainerParent } = useStyles({fullScreenActive});

  useEffect(()=>{
    setTimeout(()=>{
      setIsDataLoaded(!isDataLoaded)
    },500)
  },[])

  return (
    <Grid container className={dashboardRootContainerParent}>
      {
        isDataLoaded ?
        <>
        <Header />
      <DashboardContainer setFullScreenActive={setFullScreenActive} fullScreenActive={fullScreenActive}/>
      {!fullScreenActive &&  <Footer/>}
      </>
      :
      <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <img src={SsOptLoader}  width={"10%"} />
      </div>
      }
      
     
    </Grid>
  );
};

export default Dashboard;
