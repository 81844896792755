import { styled } from "@mui/material/styles";

export const RootContainer = styled("div")`
  /* width: 25vw;
    height: calc(100vh - 6.4vw); */
  /* overflow-y: scroll; */
  width: calc(100% - 1.8vw);
  margin: 3%;
  border-radius: 5px;
  background-color: #092e2b;
  border: 1px solid #4e6361;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
`;

export const TitleContainer = styled("div")`
  color: #fff;
  font-size: 0.78vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  flex: 1;
`;

export const ContentContainer = styled("div")`
  padding: 0.4vw;
  border-top: 1px solid #4e6361;
  background: #1b3937;
`;

export const TabContainer = styled("div")`
  display: flex;
  align-items: center;
  align-self: stretch;
`;

export const SearchButton = styled("img")`
  cursor: pointer;
  padding: 0.35vw;
  border-radius: 50%;
  background-color: #2d5d59;
  width: 0.7vw;
  height: auto;
`;

export const SearchCloseButton = styled("div")`
    margin-left: 0.5vw;
    font-size: 0.7vw;
    width: 1.51vw;
    height: 1.51vw;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #2D5D59;
    cursor: pointer;
`;

export const ResetButton = styled("div")`
    font-size: 0.7vw;
    color: #FFF;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TabItem = styled("div")<{ selected?: boolean }>`
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.5vw;
  color: ${({ selected }) => (selected ? "#FFF" : "#546F6D")};
  border-bottom: 2px solid ${({ selected }) => (selected ? "#FFF" : "none")};
  font-size: 0.78vw;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  cursor: pointer;

  &:last-child {
    margin-right: 0.75vw;
  }
`;

export const ContentItemContainer = styled("div")`
  margin: 0px;
  border-radius: 5px;
  border: 1px solid #4e6361;
  padding: 0.6vw 1vw;
`;

export const ContentItemTitle = styled("div")`
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;

export const ArrowContainer = styled("div")`
  display: flex;
  align-items: center;
`;

export const RootListContainer = styled("div")`
  display: flex;
  align-items: center;
  padding: 0.5vw;
`;

export const TabListContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1.14vw 0.6vw;
  border-bottom: 2px solid #122929;
  height: 2.8vw;
`;

export const SearchContainer = styled("div")`
  flex: 1;
  display: flex;
  align-items: center;
  border: 1px solid #4e6361;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
`;

export const SearchInput = styled("input")`
  width: 90%;
  color: #fff;
  background-color: transparent;
  font-size: 0.78vw;
  padding: 10px 10px;
  border: none;
  outline: none;
`;

export const NoResultText = styled("div")`
    color: white;
    text-align: center;
`;
