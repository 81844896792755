import { makeStyles } from "@mui/styles";

const useStyles: any = makeStyles(
  () => ({
    dashboardCopyrights: () => ({
      fontFamily: `'Noto Sans', sans-serif`,
      fontWeight: "400",
      fontSize: "0.8vw !important",
      color: "rgba(255, 255, 255, 1)",
    }),
    copyrightsSection: () => ({
      boxShadow: "0px 0px 5px 3px rgba(24, 61, 86, 0.09)",
      background: "rgba(28, 60, 57, 1)",
      height: "2vw",
      zIndex : "1"
    }),
  }),
  { index: 1 }
);
export default useStyles;
