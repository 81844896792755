// @ts-nocheck
import React, { useEffect, useState, useMemo } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { createTheme, ThemeProvider } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useStyles from "./styles";

const RightPanelDropdown: React.FC<any> = (props) => {
  const { customSelect } = useStyles();

  const {
    defaultSelectedValue,
    selectList,
    handleSelect,
    customWidth,
    customHeight,
    disabled,
    customSelectCustom,
    drowpDownTextColor,
    dropDownBgColor,
    dropDownSelectedBgColor,
    dropDownSelectedTextColor,
    selectedValueOfRightPanelDropdown,
    setSelectedValueOfRightPanelDropdown
  } = props;

  // const [selectedValue, setselectedValue] = useState(
  //   selectList && defaultSelectedValue ? defaultSelectedValue : selectList[0]?.value
  // );

  const handleChange = (e: any) => {
    setSelectedValueOfRightPanelDropdown(e.target.value);
    handleSelect(e.target.value);
  };

  // useEffect(() => {
  //   if (selectList && selectList[0] && selectList[0].value !== 1) {
  //     setselectedValue(selectList && defaultSelectedValue ? defaultSelectedValue : selectList[0]?.value);
  //   }
  // }, [defaultSelectedValue]);

  return (
    <>
      <ThemeProvider
        theme={createTheme({
          palette: {
            primary: {
              main: dropDownBgColor ? dropDownBgColor : "#000",
            },
          },

          components: {
            MuiPaper: {
              styleOverrides: {
                root: {
                  //  top : "66vh !important",
                  marginTop: "0.2% !important",
                  backgroundColor: dropDownBgColor ? dropDownBgColor : "#000",
                },
              },
            },
            MuiMenuItem: {
              styleOverrides: {
                root: {
                  color: drowpDownTextColor ? drowpDownTextColor : "fff",
                  backgroundColor: dropDownBgColor ? dropDownBgColor : "#000",

                  fontSize: "0.75vw",

                  "&.MuiMenuItem-root": {
                    margin: "0 5%",
                  },

                  "&.Mui-selected": {
                    "&.MuiMenuItem-root": {
                      backgroundColor: dropDownSelectedBgColor
                        ? dropDownSelectedBgColor
                        : "#fff",
                      color: dropDownSelectedTextColor
                        ? dropDownSelectedTextColor
                        : "#fff",
                    },

                    // "&.Mui-focusVisible": { background: "orange" },
                  },
                  "&:hover": {
                    backgroundColor: dropDownBgColor ? dropDownBgColor : "#000",
                  },
                },
              },
            },
          },
        })}>
        <FormControl
          className={customSelectCustom ? customSelectCustom : customSelect}
          style={{
            width: customWidth,
            height: customHeight,
          }}>
          <Select
            name="select"
            value={selectedValueOfRightPanelDropdown ? selectedValueOfRightPanelDropdown : ""}
            onChange={handleChange}
            disabled={false}
            displayEmpty
            renderValue={() => selectedValueOfRightPanelDropdown}
            IconComponent={ExpandMoreIcon} //CustomSvgIcon
            sx={{
              height: customHeight,
              width: customWidth,
              cursor: "pointer",
            }}>
            {selectList &&
              selectList?.length > 0 &&
              selectList?.map((item: any, index: any) => (
                <MenuItem key={index} value={item.label}>
                  {item.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </ThemeProvider>
    </>
  );
};
export default RightPanelDropdown;
